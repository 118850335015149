import request from "@/utils/request";
const BASE_PATH = "/front/textbook/";

export function getChapterDetailsById(id) {
  return request({
    url: BASE_PATH + "chapter/" + id + "/details",
    method: "get",
  });
}

export function getChapterListByPartId(partId) {
  return request({
    url: BASE_PATH + "chapter/part/" + partId,
    method: "get"
  });
}