<template>
  <div class="EduHome">
    <EduHeader />
    <main class="SubjectMain">
      <NavInfo
        @festival="festivalFn"
        @courseList="courseListFn"
        @coursePartData="coursePartDataFn"
        @courseChapterData="courseChapterDataFn"
        @courseSectionData="courseSectionDataFn"
        @courseSegmentData="courseSegmentDataFn"
        @cType="cTypeFn"
        @setSearch="setSearch"
        @updateCourseList="updateCourseListFn"
        @clearCardList="clearCardListFn"
        @payTip="payTip"
        :course-id="courseId"
        :sectionId="sectionId"
        :segmentId="segmentId"
        :chapterId="chapterId"
        :kw="kw"
        :time="time"
        ref="navInfo"
      />
      <div class="SubjectMainRight">
        <div
          class="containerCenter"
          v-if="courseData != null && cType === 11 && cardList.length == 0"
        >
          <div class="containerCenter-Title">{{ courseData.title }}</div>
          <div class="containerCenter-Font" v-html="courseData.foreword"></div>
          <div class="containerCenter-video" v-if="courseData.coverVideo != ''">
            <video-player
              id="videoPlayer1"
              ref="videoPlayer1"
              class="vjs-custom-skin"
              :options="playerOptions1"
              @fullscreenchange="fullscreenchange"
              @showvideodocument="showVideoDocument"
            />
          </div>
          <!--<video
            id="player-container-id0"
            playsinline
            webkit-playsinline
            x5-playsinline
          ></video>-->
          <img
            v-if="courseData.coverImage"
            width="100%"
            style="margin-top: 20px"
            :src="courseData.coverImage"
          />
          <div
            class="containerCenter-Font"
            v-html="courseData.introduction"
          ></div>
          <div
            v-if="!courseData.valid && courseData.price != 0"
            class="purchaseItem"
          >
            <table border="0" cellpadding="0" cellspacing="0">
              <tr>
                <td align="left">
                  <div class="purchaseItemTitle" v-if="type == 'COURSE'">
                    购买本课程（共{{ courseData.count }}篇）
                  </div>
                  <div class="purchaseItemTitle" v-if="type == 'PART'">
                    购买本篇（共{{ courseData.count }}章）
                  </div>
                  <div class="purchaseItemTitle" v-if="type == 'CHAPTER'">
                    购买本章（共{{ courseData.count }}节）
                  </div>
                  <div class="purchaseItemTitle" v-if="type == 'SECTION'">
                    购买本节（共{{ courseData.count }}段）
                  </div>
                  <div
                    class="purchaseItemTime"
                    v-if="courseData.maxTimeCapsule"
                  >
                    有效期：{{ addDays(courseData.maxTimeCapsule) }}
                  </div>
                  <div class="purchaseItemTime" v-if="courseData.timeCapsule">
                    有效期：{{ addDays(courseData.timeCapsule) }}
                  </div>
                </td>
                <td align="center">
                  <div class="purchaseItemMoney">
                    <!-- 优惠后￥200 -->
                    {{ courseData.priceTag }}
                    <!-- <span>优惠前￥250</span> -->
                  </div>
                </td>
                <td align="right">
                  <div class="purchaseItemButton" @click="buy">立即购买</div>
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div
          class="containerCenterList"
          v-if="
            cardList.length > 0 &&
            (cType === 1 || cType == 3 || cType == 4) &&
            courseInfo.visible
          "
        >
          <div class="containerCenter-Title" v-if="courseData != null">
            <div class="title">{{ courseData.title }}</div>
            <div
              v-if="!courseData.valid && courseData.price != 0"
              class="buyBtn"
              @click="buyBouquet"
            >
              <span>购买</span>
              <i><img src="~@/assets/shoping-cart-icon.png" /></i>
            </div>
          </div>

          <dl v-if="cardList.length > 0">
            <dd
              v-for="(courseItem, courseIndex) in cardList"
              :key="courseIndex"
            >
              <div
                v-if="courseItem.visible"
                @click="handleItemClick(courseItem, courseIndex)"
              >
                <div class="itemimg">
                  <!-- <img src="~@/assets/course/img-item-1.png"> -->
                  <img
                    v-if="
                      courseItem.coverImage != '' &&
                      courseItem.coverImage != null
                    "
                    :src="courseItem.coverImage"
                  />
                  <img v-else src="~@/assets/course/card_icon.jpg" />
                </div>
                <div class="contertp30">
                  <div class="infotitle" :title="courseItem.title">
                    {{ courseItem.title }}
                  </div>
                </div>
              </div>
            </dd>
          </dl>
          <div v-else class="addCourse-more">
            <div>没有数据</div>
          </div>
        </div>
        <div
          class="SubjectCenter"
          v-if="segmentDetailData != null && cType === 2"
        >
          <div class="SubjectCenter-Title">{{ segmentDetailData.title }}</div>
          <div
            v-if="segmentDetailData.subtitle != ''"
            class="SubjectCenter-Sub-Title"
          >
            &nbsp;&nbsp;{{ segmentDetailData.subtitle }}
          </div>
          <div
            v-if="
              segmentDetailData.foreword &&
              segmentDetailData.foreword != null &&
              isRichTextNotNull(segmentDetailData.foreword)
            "
            class="SubjectCenter-Sub-Foreword"
          >
            <span v-html="segmentDetailData.foreword"></span>
          </div>
          <!-- <div v-if="segmentDetailData.quiz" class="SubjectCenter-Title">
            {{ segmentDetailData.title }}
            <div v-if="countdown > 0" class="TestPaperCenter-time">
              倒计时：<span>{{ countdown }}</span>
            </div>
          </div> -->
          <!--@scroll.passive="getScroll($event)"-->
          <!--<div ref="scrollCur">-->
          <!-- resize:both; overflow: auto;设置可以拉动视频窗口大小-->
          <!-- style="position: sticky; top: 3.571vw; z-index: 999"-->
          <div
            id="videoPlayer2Div"
            ref="videoPlayer2Div"
            :class="{ w164jian: scrollCurTR }"
            class="SubjectCenter-video"
            style="top: 3.571vw; z-index: 999"
          >
            <!-- v-if="
                (mediaFileData.deliveryUrl == '' ||
                  mediaFileData.deliveryUrl == null) &&
                segmentDetailData.video
              " -->
            <video-player
              v-if="segmentDetailData.video && segmentDetailData.video != null"
              v-drag
              drag="false"
              id="videoPlayer2"
              ref="videoPlayer2"
              class="vjs-custom-skin"
              :options="playerOptions2"
              @fullscreenchange="fullscreenchange"
              @showvideodocument="showVideoDocument"
              @setDrag="setDrag"
              @ended="onPlayerEnded($event)"
            />
            <!-- <video
              v-else-if="
                segmentDetailData.video &&
                mediaFileData.deliveryUrl != '' &&
                mediaFileData.deliveryUrl != null"
              id="player-container-id"
              width="700px"
              height="450px"
              preload="auto" playsinline webkit-playsinline
            ></video>  -->

            <img
              v-if="segmentDetailData.image"
              width="100%"
              style="margin-top: 20px"
              :src="segmentDetailData.image.imageUrl"
            />
            <!--http://frontpage.siriux.cygnux.cn/upload/pdf/6b/cf15/d572/f9aa/abd12dee313558f19b.pdf 正式服务器要是不显示就加载前缀-->
            <iframe
              width="100%"
              height="700px"
              style="margin-top: 20px"
              v-if="segmentDetailData.pdf"
              :src="segmentDetailData.pdf.pdfUrl"
            />
          </div>
          <!--</div>-->
          <div
            v-if="
              segmentDetailData.video &&
              segmentDetailData.video != null &&
              isShowVideoDocument &&
              isRichTextNotNull(segmentDetailData.video.description)
            "
            style="
              margin-top: 20px;
              padding: 10px;
              height: 200px;
              overflow-y: scroll;
            "
            class="Subject-or"
            v-html="segmentDetailData.video.description"
          ></div>
          <div v-if="segmentDetailData.quiz">
            <div
              v-for="(topicItem, topicIndex) in segmentDetailData.quiz.topics"
              :key="topicIndex"
            >
              <topic
                :topicData="topicItem.topic"
                :presentation="segmentDetailData.quiz.presentation"
                :topicIndex="topicIndex"
                :topicVOIndex="topicIndex"
                :isAnswerSubmit="isAnswerSubmit"
                refKind="Quiz"
                :refId="segmentDetailData.id"
                :segmentId="segmentDetailData.id"
                wrongTopicRefKind="Exam"
                :wrongTopicRefId="courseId"
                :chapterId="
                  courseInfo.children[coursePartIndex].children[
                    courseChapterIndex
                  ].id
                "
                :sectionId="
                  courseInfo.children[coursePartIndex].children[
                    courseChapterIndex
                  ].children[courseSectionIndex].id
                "
                @set-topic-answers="setTopicAnswers"
                @refresh-tree="refreshTree"
                arrowType="favorite"
              />
            </div>
            <div
              class="outlineBut"
              v-if="
                segmentDetailData.quiz.presentation == 'TILL_ALL' &&
                !isAnswerSubmit
              "
            >
              <button class="but2" type="button" @click="answerSubmit">
                提交
              </button>
            </div>
          </div>
          <div
            v-for="(practiceItem, practiceIndex) in segmentDetailData.practices"
            :key="practiceIndex"
          >
            <div
              v-for="(topicItem, topicIndex) in practiceItem.topics"
              :key="topicIndex"
            >
              <topic
                :practiceIndex="practiceIndex"
                :topicVOIndex="
                  practiceIndex > 0
                    ? topicIndex +
                      segmentDetailData.practices[practiceIndex - 1].topics
                        .length
                    : topicIndex
                "
                :topicData="topicItem.topic"
                :presentation="practiceItem.presentation"
                @set-topic-answers="setTopicAnswers"
                @refresh-tree="refreshTree"
                :topicIndex="topicIndex"
                :isAnswerSubmit="practiceItem.isAnswerSubmit"
                refKind="Practice"
                :refId="practiceItem.id"
                wrongTopicRefKind="Practice"
                :wrongTopicRefId="courseId"
                :chapterId="
                  courseInfo.children[coursePartIndex].children[
                    courseChapterIndex
                  ].id
                "
                :sectionId="
                  courseInfo.children[coursePartIndex].children[
                    courseChapterIndex
                  ].children[courseSectionIndex].id
                "
                :segmentId="segmentDetailData.id"
                ref="topic"
                arrowType="favorite"
              />
            </div>
            <div
              class="outlineBut"
              v-if="
                practiceItem.presentation == 'TILL_ALL' &&
                !practiceItem.isAnswerSubmit
              "
            >
              <button
                v-if="!practiceItem.isAnswerSubmit"
                class="but2"
                type="button"
                @click="practiceAnswerSubmit(practiceIndex, practiceItem)"
              >
                提交
              </button>
            </div>
            <div
              class="outlineBut"
              v-else-if="
                practiceItem.presentation == 'TILL_ALL' &&
                practiceItem.isAnswerSubmit
              "
            >
              <button
                class="but2"
                type="button"
                @click="resetPracticeAnswerSubmit(practiceIndex, practiceItem)"
              >
                重做
              </button>
            </div>
          </div>
        </div>
        <!-- <div v-if="!isSearch">
          <div
            v-if="
              (cardList.length > 0 && cType === 1 && courseInfo.visible) ||
              (segmentDetailData != null && cType === 2)
            "
            class="nextPage"
          >
            <button
              v-if="coursePartIndex != -1"
              class="but2"
              type="button"
              @click="prePage"
            >
              上一页
            </button>
            <button class="but2" type="button" @click="nextPage">下一页</button>
          </div>
        </div> -->

        <div>
          <div v-if="courseListData.length > 0" class="nextPage">
            <button
              v-if="courseListIndex > 0"
              class="but2"
              type="button"
              @click="prePage"
            >
              上一页
            </button>
            <button
              v-if="courseListIndex < courseListData.length - 1"
              class="but2"
              type="button"
              @click="nextPage"
            >
              完成进入下一页
            </button>
          </div>
        </div>
      </div>
      <cold-call
        v-if="isShowColdCall"
        :coldCallData="coldCallData"
        @cold-call-success="coldCallSuccess"
      />
      <pay v-show="isPay" ref="pay" @success="paySuccess" @close="closePay" />
    </main>
    <div class="showFile" v-if="fileShow">
      <div class="showFile-info">
        <i @click="fileShow = false"><img src="~@/assets/icon-gb.png" /></i>
        <div class="showFile-info-font">
          <span>恭喜您</span>完成了本页学习！<br />您的正确率为<span>{{
            correctRate
          }}</span
          >%。
        </div>
        <div class="showFile-info-button" @click="repetition">
          想再练习一次吗？
        </div>
        <div class="showFile-info-h">
          <span @click="nextPage">跳过，完成进入下一页</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EduHeader from "@/components/EduHeader";
import NavInfo from "@/components/NavInfo";
import ColdCall from "./components/ColdCall/index.vue";
import pay from "./components/Pay/index.vue";
import Topic from "@/components/Question/Topic.vue";
// import "video.js/dist/video-js.css";
// import "@/components/VueVideoPlayer/custom-theme.css";
import videoPlayer from "@/components/VueVideoPlayer/player.vue";
//import TcAdapter from "tcadapter";
import { addDays, secondsToTimeStr, isRichTextNotNull } from "@/utils/validate";

import {
  postSegmentsVideoProgress,
  postSegmentsProgress,
} from "@/api/memberResourceProgressController";
import {
  getSegmentDetailsById,
  getSegmentColdcall,
  getSegmentListBySectionId,
  // getPracticesById,
  // getColdcallById,
} from "@/api/segmentController";
import {
  getPartDetailsById,
  getPartListByCourseId,
  // getPracticesById,
  // getColdcallById,
} from "@/api/partController";
import {
  getChapterDetailsById,
  getChapterListByPartId,
  // getPracticesById,
  // getColdcallById,
} from "@/api/chapterController";

import {
  getSectionDetailsById,
  getSectionListByChapterId,
  // getPracticesById,
  // getColdcallById,
} from "@/api/sectionController";

import { postVisitsCourse } from "@/api/memeberCourseVisits";
//import "vue-video-player/src/custom-theme.css";
// import videojs from "video.js";
import {
  // category,
  courseDetails,
  // partDetails,
  // chapterDetails,
  // sectionDetails,
  // segmentDetails,
  practicesDetails,
  getCourseById,
} from "@/api/course";
import {
  practiceTopicResultSubmit,
  quizTopicResultSubmit,
} from "@/api/memberTopicResultController";
import { getMediaFileInfo } from "@/api/materialController";
export default {
  name: "EduHome",
  data() {
    return {
      fileShow: false,
      correctRate: 100,
      activeName: "Teaching",
      isFullscreen: false,
      scanningCodeUrl: "",
      playerOptions1: {
        playbackRates: [0.7, 1.0, 1.25, 1.5, 2.0], // 播放速度
        autoplay: false, // 如果true,浏览器准备好时开始回放。
        controls: true, // 控制条
        preload: "auto", // 视频预加载
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [],
        poster: "", // 你的封面地址
        width: document.documentElement.clientWidth,
        notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        isShowVideoDocBtn: true,
      },
      playerOptions2: {
        playbackRates: [0.7, 1.0, 1.25, 1.5, 2.0], // 播放速度
        autoplay: false, // 如果true,浏览器准备好时开始回放。
        controls: true, // 控制条
        preload: "auto", // 视频预加载
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [],
        poster: "", // 你的封面地址
        width: document.documentElement.clientWidth,
        notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        isShowVideoDocBtn: true,
        isShowDragTip: false,
        isDrag: true,
        showDragTipDuration: 3000,
      },
      courseInfo: [],
      courseData: null,
      courseListData: [],
      courseListIndex: 0,
      courseDetailsInfo: {},
      cType: 1,
      backCType: 0,
      entityInfo: {},
      courseId: -1,
      coursePartIndex: -1,
      courseChapterIndex: -1,
      courseSectionIndex: -1,
      courseSegmentIndex: -1,
      coursePartData: null,
      coursePartDetailData: null,
      courseChapterData: null,
      courseChapterDetailData: null,
      courseSectionData: null,
      courseSectionDetailData: null,
      courseSegmentData: null,
      segmentDetailData: null,
      isAnswerSubmit: false,
      type: "COURSE",
      refKind: "",
      practiceAnswers: [],
      quizAnswers: [],
      countdown: 0,
      countdownTimeout: -1,
      coldcallTimeout: -1,
      isShowColdCall: false,
      coldCallData: {},
      answerReportStartTime: 0,
      segmentId: -1,
      chapterId: -1,
      isShowVideoDocument: true,
      isBackShowVideoDocument: false,
      sectionId: -1,
      action: null,
      time: -1,
      cardList: [],
      beforeUnloadTime: 0,
      kw: "",
      mediaFileData: {},
      clickSpend: 0,
      scrollCurTop: 0,
      scrollCurTR: false,
      videoPlayer2Width: 0,
      videoPlayer2Height: 0,
      videoPlayer2Top: 0,
      videoPlayer2Left: 0,
      isSearch: false,
      isPay: false,
    };
  },
  directives: {
    drag: {
      // 指令的定义
      bind: function (el) {
        let oDiv = el; // 获取当前元素
        oDiv.onmousedown = (e) => {
          console.log("onmousedown:" + oDiv.getAttribute("drag"));
          if (oDiv.getAttribute("drag") === "false") {
            return;
          }
          // 算出鼠标相对元素的位置
          let disX = e.clientX - oDiv.offsetLeft;
          let disY = e.clientY - oDiv.offsetTop;
          document.onmousemove = (e) => {
            // 用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
            let left = e.clientX - disX;
            let top = e.clientY - disY;

            oDiv.style.left = left + "px";
            oDiv.style.top = top + "px";
          };
          document.onmouseup = (e) => {
            document.onmousemove = null;
            document.onmouseup = null;
          };
        };
      },
    },
  },
  components: {
    EduHeader,
    NavInfo,
    videoPlayer,
    Topic,
    ColdCall,
    pay,
  },
  mounted() {
    window.addEventListener("beforeunload", (e) => this.beforeunloadHandler(e));
    //window.addEventListener("scroll", this.handleScroll, true);
    //window.addEventListener("unload", (e) => this.unloadHandler(e));
  },
  destroyed() {
    window.removeEventListener("beforeunload", (e) =>
      this.beforeunloadHandler(e)
    );
    //window.removeEventListener("scroll", this.handleScroll);
    // window.removeEventListener("unload", (e) => this.unloadHandler(e));
  },
  created() {
    if (this.$route.query.sectionId) {
      this.sectionId = parseInt(this.$route.query.sectionId);
    }
    if (this.$route.query.segmentId) {
      this.segmentId = parseInt(this.$route.query.segmentId);
    }
    if (this.$route.query.chapterId) {
      this.chapterId = parseInt(this.$route.query.chapterId);
    }
    if (this.$route.query.action) {
      this.action = this.$route.query.action;
    }
    if (this.$route.query.keyword) {
      this.kw = this.$route.query.keyword;
    }
    let id = this.getCourseId(); //sessionStorage.getItem("COURSE_ID");
    if (id == null) {
      this.$alert("当前页面为非法打开，需要从首页重新进入", "提示", {
        confirmButtonText: "确定",
        callback: (action) => {
          this.$router.push({
            path: "/home",
          });
        },
      });
      return;
    }
    this.courseId = parseInt(id);
    //alert(localStorage.getItem("PAGE_ACTION"))
  },
  watch: {
    $route: {
      // eslint-disable-next-line no-unused-vars
      handler(val, oldval) {
        if (this.$route.query.chapterId) {
          //if (!this.$route.query.segmentId) {
          if (this.chapterId != this.$route.query.chapterId) {
            this.chapterId = parseInt(this.$route.query.chapterId);
          }
          if (this.$route.query.time) {
            this.time = parseInt(this.$route.query.time);
          }
          //}
        } else if (this.$route.query.segmentId) {
          //if (!this.$route.query.segmentId) {
          if (this.segmentId != this.$route.query.segmentId) {
            this.segmentId = parseInt(this.$route.query.segmentId);
          }
          if (this.$route.query.time) {
            this.time = parseInt(this.$route.query.time);
          }
          //}
        }
      },
      deep: true,
    },
  },
  beforeDestroy() {
    window.clearTimeout(this.countdownTimeout);
    window.clearTimeout(this.coldcallTimeout);
  },
  methods: {
    unloadHandler(e) {
      let time = new Date().getTime() - this.beforeUnloadTime;
      if (this.$refs.navInfo.keyword == "") {
        if (time <= 5) {
          sessionStorage.setItem("PAGE_ACTION", 1); // 关闭
        } else {
          sessionStorage.setItem("PAGE_ACTION", 2); // 刷新
          sessionStorage.setItem("coursePartIndex", this.coursePartIndex);
          sessionStorage.setItem("courseChapterIndex", this.courseChapterIndex);
          sessionStorage.setItem("courseSectionIndex", this.courseSectionIndex);
          sessionStorage.setItem("courseSegmentIndex", this.courseSegmentIndex);
        }
      }
    },
    handleScroll() {
      // let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      // console.log(scrollTop, '滚动距离')
      // console.log(this.$refs.scrollCur.$el.getBoundingClientRect().top)
      try {
        this.scrollCurTop = this.$refs.scrollCur.getBoundingClientRect().top;
      } catch (e) {
        console.log(e);
        this.scrollCurTR = false;
        return;
      }
      console.log("this.scrollCurTop:" + this.scrollCurTop);
      if (this.scrollCurTop < 0) {
        this.scrollCurTR = true;
        var videoPlayer2Obj = document.getElementById("videoPlayer2");
        if (videoPlayer2Obj != null) {
          videoPlayer2Obj.style.width = this.videoPlayer2Width + "px";
          videoPlayer2Obj.style.height = this.videoPlayer2Height + "px";
          videoPlayer2Obj.style.position = "relative";
          document.getElementById("videoPlayer2Div").style.zIndex = 100000;
        }
        //document.getElementById("videoPlayer2Div").style.marginTop = "60px";
      } else {
        this.scrollCurTR = false;
      }
    },
    isRichTextNotNull(_text) {
      return isRichTextNotNull(_text);
    },
    beforeunloadHandler(e) {
      if (this.$route.name == "Subject") {
        this.beforeUnloadTime = new Date().getTime();
        e = e || window.event;
        if (e) {
          e.returnValue = "离开此网页,系统可能不会保存您所做的更改。";
        }
        // Chrome, Safari, Firefox 4+, Opera 12+ , IE 9+
        return "离开此网页,系统可能不会保存您所做的更改。";
      }
    },
    addDays(days) {
      return addDays(days);
    },
    repetition() {
      this.fileShow = false;
      this.$refs.navInfo.courseSegmentClickFn(
        this.coursePartIndex,
        this.courseChapterIndex,
        this.courseSectionIndex,
        this.courseSegmentIndex,
        this.courseInfo.children[this.coursePartIndex].children[
          this.courseChapterIndex
        ].children[this.courseSectionIndex].children[this.courseSegmentIndex],
        this.courseInfo.children[this.coursePartIndex].children[
          this.courseChapterIndex
        ].children[this.courseSectionIndex]
      );
    },
    add: function () {
      this.list.push({ name: "Juan" });
    },
    replace: function () {
      this.list = [{ name: "Edgard" }];
    },
    clone: function (el) {
      return {
        name: el.name + " cloned",
      };
    },
    log: function (evt) {
      window.console.log(evt);
    },
    handleChange() {
      console.log("changed");
    },
    inputChanged(value) {
      this.activeNames = value;
    },
    getComponentData() {
      return {
        onChange: this.handleChange,
        onInput: this.inputChanged,
        wrap: true,
        value: this.activeNames,
      };
    },
    cTypeFn(e) {
      this.cType = e;
      window.clearTimeout(this.countdownTimeout);
      window.clearTimeout(this.coldcallTimeout);
    },
    setSearch(isSearch) {
      this.isSearch = isSearch;
    },
    courseListFn(e) {
      //this.postVisitsCourse();
      this.courseInfo = e;
      this.courseListData = [];
      this.courseListData.push({
        id: this.courseInfo.id,
        kind: this.courseInfo.kind,
      });
      this.treeToList(this.courseInfo.children);
      this.courseListIndex = 0;
      this.coursePartIndex = -1;
      this.courseChapterIndex = -1;
      this.courseSectionIndex = -1;
      this.courseSegmentIndex = -1;
      if (this.isSearch) {
        let length = this.courseInfo.children.length;
        for (let i = 0; i < length; i++) {
          if (this.courseInfo.children[i].visible) {
            this.coursePartIndex = i;
            break;
          }
        }
      } else {
        this.getCourseById(e.id);
      }
      this.getPartListByCourseId(e.id);
      this.clickSpend = new Date().getTime();
      //this.$cookies.set("COURSE_" + id, value);
      //this.courseDetailsFn(this.courseInfo);
    },
    treeToList(tree) {
      for (var i in tree) {
        var node = tree[i];
        if (
          node != null &&
          node.visible &&
          (node.kind == "PART" ||
            node.kind == "CHAPTER" ||
            node.kind == "SECTION" ||
            node.kind == "SEGMENT")
        ) {
          this.courseListData.push({
            id: node.id,
            kind: node.kind,
          });
        }
        //如果有子结点,再遍历子结点
        if (node.children != null && node.children.length !== 0) {
          this.treeToList(node.children); //递归
        }
      }
    },
    setCourseDataIndex() {
      var id = -1,
        kind = null;
      if (this.coursePartIndex == -1) {
        //在课程上面
        this.courseListIndex = 0;
        return;
      } else if (this.coursePartIndex != -1 && this.courseChapterIndex == -1) {
        //在篇上面
        id = this.courseInfo.children[this.coursePartIndex].id;
        kind = "PART";
      } else if (
        this.coursePartIndex != -1 &&
        this.courseChapterIndex != -1 &&
        this.courseSectionIndex == -1
      ) {
        //在章上面
        id =
          this.courseInfo.children[this.coursePartIndex].children[
            this.courseChapterIndex
          ].id;
        kind = "CHAPTER";
      } else if (
        this.coursePartIndex != -1 &&
        this.courseChapterIndex != -1 &&
        this.courseSectionIndex != -1 &&
        this.courseSegmentIndex == -1
      ) {
        //在节上面
        id =
          this.courseInfo.children[this.coursePartIndex].children[
            this.courseChapterIndex
          ].children[this.courseSectionIndex].id;
        kind = "SECTION";
      } else if (
        this.coursePartIndex != -1 &&
        this.courseChapterIndex != -1 &&
        this.courseSectionIndex != -1 &&
        this.courseSegmentIndex != -1
      ) {
        //在段上面
        id =
          this.courseInfo.children[this.coursePartIndex].children[
            this.courseChapterIndex
          ].children[this.courseSectionIndex].children[this.courseSegmentIndex]
            .id;
        kind = "SEGMENT";
      }
      var length = this.courseListData.length;
      for (let i = 0; i < length; i++) {
        if (
          this.courseListData[i].id == id &&
          this.courseListData[i].kind == kind
        ) {
          this.courseListIndex = i;
          break;
        }
      }
    },
    getPartListByCourseId(id) {
      this.cardList = [];
      if (this.courseInfo.children.length == 0) {
        return;
      }
      this.cardList = this.courseInfo.children;
      this.cardList = this.cardList.filter((item) => {
        return item.visible;
      });
      // getPartListByCourseId(id).then((res) => {
      //   if (res.code == 20000) {
      //     this.cardList = res.data;
      //   } else {
      //     this.$baseMessage(res.data.msg, "error", "vab-hey-message-error");
      //   }
      // });
    },
    updateCourseListFn(e) {
      this.courseInfo = e;
      if (this.coursePartIndex == -1) {
        this.getCourseById(this.courseInfo.id);
        this.getPartListByCourseId(this.courseInfo.id);
      } else if (this.coursePartIndex >= 0 && this.courseChapterIndex == -1) {
        this.coursePartDataFn(
          this.coursePartIndex,
          this.courseInfo.children[this.coursePartIndex]
        );
      } else if (
        this.coursePartIndex >= 0 &&
        this.courseChapterIndex >= 0 &&
        this.courseSectionIndex == -1
      ) {
        this.courseChapterDataFn(
          this.coursePartIndex,
          this.courseInfo.children[this.coursePartIndex].children[
            this.courseChapterIndex
          ]
        );
      } else if (
        this.coursePartIndex >= 0 &&
        this.courseChapterIndex >= 0 &&
        this.courseSectionIndex >= 0
      ) {
        this.courseSectionDataFn(
          this.coursePartIndex,
          this.courseChapterIndex,
          this.courseSectionIndex,
          this.courseInfo.children[this.coursePartIndex].children[
            this.courseChapterIndex
          ].children[this.courseSectionIndex]
        );
      }
    },
    buy() {
      //this.backCType = this.cType;
      //this.cType = 3;
      this.isPay = true;
      this.$refs.pay.showType("list", "");
    },
    buyBouquet() {
      // let linkedBouquetId = 0;
      // if (this.coursePartIndex == -1) {
      //   linkedBouquetId = this.courseInfo.linkedBouquetId;
      // } else if (this.coursePartIndex != -1 && this.courseChapterIndex == -1) {
      //   linkedBouquetId =
      //     this.courseInfo.children[this.coursePartIndex].linkedBouquetId;
      //   if (linkedBouquetId == 0) {
      //     linkedBouquetId = this.courseInfo.linkedBouquetId;
      //   }
      // } /*else if (this.coursePartIndex != -1 && this.courseChapterIndex != -1) {

      // }*/
      // if (linkedBouquetId == 0) {
      this.buy();
      // } else {
      // this.$router.push({
      //   path: "/purchaseShow", //+ id
      //   query: { id: linkedBouquetId },
      // });
      // }
    },
    closePay() {
      this.isPay = false;
    },
    paySuccess() {
      this.closePay();
      this.$refs.navInfo.getCourseTree(2);
    },
    coldCallSuccess() {
      this.isShowColdCall = false;
    },
    async getCourseById(id) {
      window.clearTimeout(this.countdownTimeout);
      window.clearTimeout(this.coldcallTimeout);
      this.isAnswerSubmit = false;
      this.coursePartIndex = -1;
      this.courseChapterIndex = -1;
      this.courseSectionIndex = -1;
      this.courseSegmentIndex = -1;
      this.type = "COURSE";
      getCourseById(id).then((res) => {
        if (res.code == 20000) {
          this.closePlay();
          this.courseData = res.data;
          this.courseData.valid = this.courseInfo.valid;
          if (this.courseInfo.children != null) {
            this.courseData.count = this.courseInfo.children.length;
          }
          if (res.data.coverVideo != "") {
            this.setPlay1("video/mp4", res.data.coverVideo);
          }
        } else {
          this.$baseMessage(res.data.msg, "error", "vab-hey-message-error");
        }
      });

      // const { code, data } = await getCourseById(id);
      // if (code == 20000) {
      //   this.closePlay();
      //   this.courseData = data;
      //   this.setPlay1("video/mp4", data.coverVideo);
      // } else {
      //   this.$baseMessage(data.msg, "error", "vab-hey-message-error");
      // }
    },
    coursePartDataFn(partIndex, partItem) {
      window.clearTimeout(this.countdownTimeout);
      window.clearTimeout(this.coldcallTimeout);
      this.postVisitsCourse();
      this.coursePartIndex = partIndex;
      this.courseChapterIndex = -1;
      this.courseSectionIndex = -1;
      this.courseSegmentIndex = -1;
      this.coursePartData = partItem;
      this.type = "PART";
      this.isAnswerSubmit = false;
      let cookieJson = {
        partId: this.courseInfo.children[partIndex].id,
        chapterId: -1,
        sectionId: -1,
        segmentId: -1,
      };
      this.$cookies.set(
        "COURSE_" + this.courseId,
        JSON.stringify(cookieJson),
        "30d"
      );
      this.setCourseDataIndex();
      getPartDetailsById(this.coursePartData.id).then((res) => {
        if (res.code == 20000) {
          this.closePlay();
          this.courseData = res.data;
          this.courseData.valid = this.courseInfo.children[partIndex].valid;
          if (this.courseData.chapters != null) {
            this.courseData.count = this.courseData.chapters.length;
          }
          if (res.data.coverVideo != "") {
            this.setPlay1("video/mp4", res.data.coverVideo);
          }
        } else {
          this.$baseMessage(res.data.msg, "error", "vab-hey-message-error");
        }
      });
      this.getChapterListByPartId(this.coursePartData.id);
      this.clickSpend = new Date().getTime();
    },
    getChapterListByPartId(id) {
      this.cardList = [];
      //alert(JSON.stringify(this.courseInfo.children[this.coursePartIndex].children))
      this.cardList = this.courseInfo.children[this.coursePartIndex].children;
      this.cardList = this.cardList.filter((item) => {
        return item.visible;
      });
      // getChapterListByPartId(id).then((res) => {
      //   if (res.code == 20000) {
      //     this.cardList = res.data;
      //   } else {
      //     this.$baseMessage(res.data.msg, "error", "vab-hey-message-error");
      //   }
      // });
    },
    courseChapterDataFn(partIndex, chapterIndex, chapterItem) {
      this.postVisitsCourse();
      this.isAnswerSubmit = false;
      window.clearTimeout(this.countdownTimeout);
      window.clearTimeout(this.coldcallTimeout);
      this.coursePartIndex = partIndex;
      this.courseChapterIndex = chapterIndex;
      this.courseSectionIndex = -1;
      this.courseSegmentIndex = -1;
      this.courseChapterData = chapterItem;
      this.type = "CHAPTER";
      let cookieJson = {
        partId: this.courseInfo.children[partIndex].id,
        chapterId:
          this.courseInfo.children[partIndex].children[chapterIndex].id,
        sectionId: -1,
        segmentId: -1,
      };
      this.$cookies.set(
        "COURSE_" + this.courseId,
        JSON.stringify(cookieJson),
        "30d"
      );
      this.setCourseDataIndex();
      getChapterDetailsById(this.courseChapterData.id).then((res) => {
        if (res.code == 20000) {
          this.closePlay();
          this.courseData = res.data;
          this.courseData.valid =
            this.courseInfo.children[partIndex].children[chapterIndex].valid;
          if (this.courseData.sections != null) {
            this.courseData.count = this.courseData.sections.length;
          }
          if (res.data.coverVideo != "") {
            this.setPlay1("video/mp4", res.data.coverVideo);
          }
        } else {
          this.$baseMessage(res.data.msg, "error", "vab-hey-message-error");
        }
      });
      this.getSectionListByChapterId(this.courseChapterData.id);
      this.clickSpend = new Date().getTime();
    },
    getSectionListByChapterId(id) {
      this.cardList = [];
      this.cardList =
        this.courseInfo.children[this.coursePartIndex].children[
          this.courseChapterIndex
        ].children;
      this.cardList = this.cardList.filter((item) => {
        return item.visible;
      });
      // getSectionListByChapterId(id).then((res) => {
      //   if (res.code == 20000) {
      //     this.cardList = res.data;
      //   } else {
      //     this.$baseMessage(res.data.msg, "error", "vab-hey-message-error");
      //   }
      // });
    },
    courseSectionDataFn(partIndex, chapterIndex, sectionIndex, sectionItem) {
      this.postVisitsCourse();
      this.isAnswerSubmit = false;
      window.clearTimeout(this.countdownTimeout);
      window.clearTimeout(this.coldcallTimeout);
      this.coursePartIndex = partIndex;
      this.courseChapterIndex = chapterIndex;
      this.courseSectionIndex = sectionIndex;
      this.courseSectionData = sectionItem;
      this.courseSegmentIndex = -1;
      this.type = "SECTION";
      let cookieJson = {
        partId: this.courseInfo.children[partIndex].id,
        chapterId:
          this.courseInfo.children[partIndex].children[chapterIndex].id,
        sectionId:
          this.courseInfo.children[partIndex].children[chapterIndex].children[
            sectionIndex
          ].id,
        segmentId: -1,
      };
      this.$cookies.set(
        "COURSE_" + this.courseId,
        JSON.stringify(cookieJson),
        "30d"
      );
      this.setCourseDataIndex();
      if (this.courseSectionData) {
        getSectionDetailsById(this.courseSectionData.id).then((res) => {
          if (res.code == 20000) {
            this.closePlay();
            this.courseData = res.data;
            this.courseData.valid =
              this.courseInfo.children[partIndex].children[
                chapterIndex
              ].children[sectionIndex].valid;
            if (this.courseData.segments != null) {
              this.courseData.count = this.courseData.segments.length;
            }
            if (res.data.coverVideo != "") {
              this.setPlay1("video/mp4", res.data.coverVideo);
            }
          } else {
            this.$baseMessage(res.data.msg, "error", "vab-hey-message-error");
          }
        });
        this.getSegmentListBySectionId(this.courseSectionData.id);
      }
      this.clickSpend = new Date().getTime();
    },
    getSegmentListBySectionId(id) {
      this.cardList = [];
      this.cardList =
        this.courseInfo.children[this.coursePartIndex].children[
          this.courseChapterIndex
        ].children[this.courseSectionIndex].children;
      this.cardList = this.cardList.filter((item) => {
        return item.visible;
      });
      // getSegmentListBySectionId(id).then((res) => {
      //   if (res.code == 20000) {
      //     this.cardList = res.data;
      //   } else {
      //     this.$baseMessage(res.data.msg, "error", "vab-hey-message-error");
      //   }
      // });
    },
    clearCardListFn() {
      this.cardList = [];
    },
    courseSegmentDataFn(
      partIndex,
      chapterIndex,
      sectionIndex,
      segmentIndex,
      segmentItem
    ) {
      this.postVisitsCourse();
      this.isAnswerSubmit = false;
      this.type = "SEGMENT";
      this.coursePartIndex = partIndex;
      this.courseChapterIndex = chapterIndex;
      this.courseSectionIndex = sectionIndex;
      this.courseSegmentIndex = segmentIndex;
      this.courseSegmentData = segmentItem;
      let cookieJson = {
        partId: this.courseInfo.children[partIndex].id,
        chapterId:
          this.courseInfo.children[partIndex].children[chapterIndex].id,
        sectionId:
          this.courseInfo.children[partIndex].children[chapterIndex].children[
            sectionIndex
          ].id,
        segmentId:
          this.courseInfo.children[partIndex].children[chapterIndex].children[
            sectionIndex
          ].children[segmentIndex].id,
      };
      this.$cookies.set(
        "COURSE_" + this.courseId,
        JSON.stringify(cookieJson),
        "30d"
      );
      this.setCourseDataIndex();
      if (this.action != null && this.action == "refresh") {
        this.action = null;
        this.getSegmentDetailsById(segmentItem.id);
      } else if (
        this.action != null &&
        this.action == "nextPage" &&
        this.segmentDetailData != null
      ) {
        this.nextPage();
        this.action = null;
      } else {
        this.getSegmentDetailsById(segmentItem.id);
      }
      //this.clickSpend = new Date().getTime();
      //this.postVisitsCourse();
    },
    postVisitsCourse() {
      if (this.clickSpend != 0) {
        let visitsJson = {
          chapterId: null,
          courseId: this.courseInfo.id,
          partId: null,
          sectionId: null,
          segmentId: null,
          spend: (new Date().getTime() - this.clickSpend) / 1000,
        };
        if (
          this.coursePartIndex != -1 &&
          this.courseChapterIndex != -1 &&
          this.courseSectionIndex != -1 &&
          this.courseSegmentIndex != -1
        ) {
          visitsJson.partId = this.courseInfo.children[this.coursePartIndex].id;
          visitsJson.chapterId =
            this.courseInfo.children[this.coursePartIndex].children[
              this.courseChapterIndex
            ].id;
          visitsJson.sectionId =
            this.courseInfo.children[this.coursePartIndex].children[
              this.courseChapterIndex
            ].children[this.courseSectionIndex].id;
          visitsJson.segmentId =
            this.courseInfo.children[this.coursePartIndex].children[
              this.courseChapterIndex
            ].children[this.courseSectionIndex].children[
              this.courseSegmentIndex
            ].id;
        } else if (
          this.coursePartIndex != -1 &&
          this.courseChapterIndex != -1 &&
          this.courseSectionIndex != -1
        ) {
          visitsJson.partId = this.courseInfo.children[this.coursePartIndex].id;
          visitsJson.chapterId =
            this.courseInfo.children[this.coursePartIndex].children[
              this.courseChapterIndex
            ].id;
          visitsJson.sectionId =
            this.courseInfo.children[this.coursePartIndex].children[
              this.courseChapterIndex
            ].children[this.courseSectionIndex].id;
        } else if (
          this.coursePartIndex != -1 &&
          this.courseChapterIndex != -1
        ) {
          visitsJson.partId = this.courseInfo.children[this.coursePartIndex].id;
          visitsJson.chapterId =
            this.courseInfo.children[this.coursePartIndex].children[
              this.courseChapterIndex
            ].id;
        } else if (this.coursePartIndex != -1) {
          visitsJson.partId = this.courseInfo.children[this.coursePartIndex].id;
        }
        postVisitsCourse(visitsJson);
      }
      this.clickSpend = new Date().getTime();
    },
    async getSegmentDetailsById(id) {
      window.clearTimeout(this.countdownTimeout);
      window.clearTimeout(this.coldcallTimeout);
      this.isAnswerSubmit = false;
      this.refKind = "";
      this.scrollCurTR = false;
      this.segmentDetailData = null;
      this.practiceAnswers = [];
      this.quizAnswers = [];
      const { code, data } = await getSegmentDetailsById(id);
      if (code == 20000) {
        this.closePlay();
        if (data.video) {
          this.setPlay2("video/mp4", data.video); //data.video.videoUrl
        }
        if (data.practices != null) {
          let practicesLength = data.practices.length;
          for (let i = 0; i < practicesLength; i++) {
            this.$set(data.practices[i], "isAnswerSubmit", false);
            this.practiceAnswers.push([]);
            let topicsLength = data.practices[i].topics.length;
            for (let j = 0; j < topicsLength; j++) {
              this.practiceAnswers[i].push({});
            }
          }
          this.refKind = "Practice";
        }
        if (data.quiz != null) {
          let topicsLength = data.quiz.topics.length;
          for (let j = 0; j < topicsLength; j++) {
            this.quizAnswers.push({});
          }
          this.refKind = "Quiz";
          if (data.duration > 0) {
            //this.countdown = 0;
            //this.quziCountdown(data.duration);
          }
        } else {
          // window.clearTimeout(this.coldcallTimeout);
          // let that = this;
          // let duration = Math.floor(Math.random() * data.duration);
          // duration = 1;
          // window.coldcallTimeout = setTimeout(function () {
          //   that.getSegmentColdcall(id);
          // }, duration * 1000);
          if (!data.video) {
            this.colcCallRquest(id, data.duration);
          }
        }
        this.segmentId = id;
        this.segmentDetailData = data;
        if (this.segmentDetailData.video) {
          var that = this;
          //this.isShowVideoDocument = false;
          // setTimeout(function () {
          //   let width = that.$refs.videoPlayer2Div.clientWidth;
          //   let height = that.$refs.videoPlayer2Div.clientHeight;
          //   let top = that.$refs.videoPlayer2Div.offsetTop;
          //   let left = that.$refs.videoPlayer2Div.offsetLeft;
          //   that.$refs.videoPlayer2.style.position = "absolute";
          //   that.$refs.videoPlayer2.style.left = left + "px";
          //   that.$refs.videoPlayer2.style.top = top + "px";
          //   that.$refs.videoPlayer2.style.width = width + "px";
          //   that.$refs.videoPlayer2.style.height = height + "px";
          //   that.videoPlayer2Width = width;
          //   that.videoPlayer2Height = height;
          //   that.videoPlayer2Top = top;
          //   that.videoPlayer2Left = left;
          // }, 100);
        }
        this.answerReportStartTime = new Date().getTime();
        if (this.action != null && this.action == "nextPage") {
          this.action = null;
          this.fileShow = true;
          this.nextPage();
        }
      } else {
        this.$baseMessage(data.msg, "error", "vab-hey-message-error");
      }
    },
    colcCallRquest(id, _duration) {
      window.clearTimeout(this.coldcallTimeout);
      let that = this;
      let duration = Math.floor(Math.random() * _duration);
      //duration = 1;
      this.coldcallTimeout = setTimeout(function () {
        that.getSegmentColdcall(id);
      }, duration * 1000);
    },
    onPlayerEnded(event) {
      this.colcCallRquest(this.segmentId, this.segmentDetailData.duration);
      let json = {
        courseId: this.courseId,
        segmentId: this.segmentId,
        spend: event.duration(),
        videoId: 0,
      };
      if (
        this.segmentDetailData.quiz == null &&
        this.segmentDetailData.practices == null
      ) {
        this.postSegmentsProgress();
      }
      postSegmentsVideoProgress(json).then((res) => {
        if (res.code == 20000) {
          this.refreshTree(-1, -1);
        }
      });
    },
    refreshTree(practiceIndex, topicIndex) {
      //alert("practiceIndex:" + practiceIndex + ",topicIndex:" + topicIndex)
      if (practiceIndex != -1) {
        //表示练习题
        if (
          this.segmentDetailData.practices.length - 1 == practiceIndex &&
          topicIndex ==
            this.segmentDetailData.practices[practiceIndex].topics.length - 1
        ) {
          this.postSegmentsProgress();
        }
      } else if (topicIndex != -1) {
        //表示真题
        if (this.segmentDetailData.quiz.topics.length - 1 == topicIndex) {
          this.postSegmentsProgress();
        }
      }
      this.$refs.navInfo.getCourseTree(1);
    },
    getSegmentColdcall(id) {
      getSegmentColdcall(id).then((res) => {
        if (res.code == 20000) {
          if (res.data.id == null) {
            return;
          }
          //if (res.data.topic.type == "ESSAY_QUESTION") {
          this.closePlay();
          this.isShowColdCall = true;
          this.coldCallData = res.data;
          //}
        } else {
          this.$baseMessage(res.data.msg, "error", "vab-hey-message-error");
        }
      });
    },
    quziCountdown(duration) {
      window.clearTimeout(this.countdownTimeout);
      let that = this;
      this.countdown = secondsToTimeStr(duration);
      if (duration == 0) {
        this.answerSubmit();
        return;
      }
      this.countdownTimeout = setTimeout(function () {
        that.quziCountdown(--duration);
      }, 1000);
    },
    initSingleChoice(question) {
      this.$set(question, "answer", "");
    },
    initMultipleChoice(question) {
      this.$set(question, "answer", []);
    },
    initGapFillingOrEssay(question) {
      let fieldsLength = question.fields.length;
      for (let k = 0; k < fieldsLength; k++) {
        this.$set(question.fields[k], "answer", "");
      }
    },
    initTrueOrFalse(question) {
      this.$set(question, "answer", "");
    },
    initAccounting(question) {
      let accounts = question.accounts;
      let accountsLength = accounts.length;
      for (let l = 0; l < accountsLength; l++) {
        let account = accounts[l];
        if (account.name != "") {
          this.$set(account, "answer", "");
        }
        let accountEntries = account.accountEntries;
        let accountEntriesLength = accountEntries.length;
        this.$set(account, "accountEntriesOptions", []);
        for (let m = 0; m < accountEntriesLength; m++) {
          let accountEntrieData = accountEntries[m];
          account.accountEntriesOptions.push({
            id: "name_" + accountEntrieData.id,
            name: accountEntrieData.name,
          });
          account.accountEntriesOptions.push({
            id: "value_" + accountEntrieData.id,
            name: accountEntrieData.value,
          });
          this.$set(accountEntrieData, "accountEntriesNames", []);
          this.$set(accountEntrieData, "accountEntriesValues", []);
          this.$set(accountEntrieData, "accountEntriesTypes", []);
        }
        account.accountEntriesOptions.sort(function () {
          return 0.5 - Math.random();
        });
      }
    },
    async courseDetailsFn(row) {
      row.id = this.courseId;
      //获取课程
      const { code, data } = await courseDetails(row.id);
      if (code == 20000) {
        this.courseDetailsInfo = data;
        this.playerOptions = {
          playbackRates: [0.7, 1.0, 1.25, 1.5, 2.0], // 播放速度
          autoplay: true, // 如果true,浏览器准备好时开始回放。
          controls: true, // 控制条
          preload: "auto", // 视频预加载
          muted: false, // 默认情况下将会消除任何音频。
          loop: false, // 导致视频一结束就重新开始。
          language: "zh-CN",
          aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
          fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
          sources: this.setPlay("video/mp4", data.coverVideo),
          poster: data.coverImage, // 你的封面地址
          width: document.documentElement.clientWidth,
          notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
          isShowVideoDocBtn: true,
        };
      } else {
        this.$baseMessage(data.msg, "error", "vab-hey-message-error");
      }
    },
    festivalFn(e) {
      this.entityInfo = e;
      this.playerOptions = {
        playbackRates: [0.7, 1.0, 1.25, 1.5, 2.0], // 播放速度
        autoplay: true, // 如果true,浏览器准备好时开始回放。
        controls: true, // 控制条
        preload: "auto", // 视频预加载
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: this.setPlay("video/mp4", e.video),
        poster: e.image, // 你的封面地址
        width: document.documentElement.clientWidth,
        notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        isShowVideoDocBtn: true,
      };
      this.practicesDetailsFn(e);
    },
    async practicesDetailsFn(row) {
      //获取段
      const { code, data } = await practicesDetails(row);
      if (code == 20000) {
        // this.practicesList =data
      } else {
        this.$baseMessage(data.msg, "error", "vab-hey-message-error");
      }
    },
    fullscreenchange() {
      this.isFullscreen = !this.isFullscreen;
      if (this.isFullscreen) {
        this.isBackShowVideoDocument = this.isShowVideoDocument;
        this.isShowVideoDocument = false;
      } else {
        this.isShowVideoDocument = this.isBackShowVideoDocument;
      }
    },
    setPlay1(type, url) {
      //this.playerOptions1.sources = [{ type: type, src: url }];
      // const adapter = new TcAdapter("player-container-id0", {
      //   appID: '1500002611',
      //   fileID: '5285890813738446783',
      //   psign: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBJZCI6MTUwMDAwMjYxMSwiZmlsZUlkIjoiNTI4NTg5MDgxMzczODQ0Njc4MyIsImN1cnJlbnRUaW1lU3RhbXAiOjE2MTU5NTEyMzksImV4cGlyZVRpbWVTdGFtcCI6MjIxNTY1MzYyMywicGNmZyI6ImJhc2ljRHJtUHJlc2V0IiwidXJsQWNjZXNzSW5mbyI6eyJ0IjoiMjIxNTY1MzYyMyJ9fQ.hRrQYvC0UYtcO-ozB35k7LZI6E3ruvow7DC0XzzdYKE',
      //   hlsConfig: {},
      // }, function(){
      //  console.log('basicInfo', adapter.getVideoBasicInfo());
      // });
      // adapter.on(TcAdapter.TcAdapterEvents.HLSREADY,function() {
      //   const hls = adapter.hls;
      //       // ...
      // })
    },
    setPlay2(type, video) {
      this.mediaFileData = {};
      //url
      getMediaFileInfo({ filePath: video.videoUrl }).then((res) => {
        if (res.code == 20000) {
          this.mediaFileData = res.data;
          //alert(JSON.stringify(res.data));
          //alert(res.data.deliveryUrl)
          if (res.data.deliveryUrl != "" && res.data.deliveryUrl != null) {
            // const adapter = new TcAdapter("videoPlayer2", {
            //   appID: "1309121823",
            //   fileID: res.data.deliveryUrl,
            //   autoplay: false,
            //   function() {
            //     alert("dddd")
            //   }
            // });
            //             this.$nextTick(() => {
            //               var player = TCPlayer('player-container-id', { // player-container-id 为播放器容器 ID，必须与 html 中一致
            //                 appID: "1500015203",
            //                 fileID: "387702306931642693",
            //                 psign:
            //                       "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBJZCI6MTUwMDAxNTIwMywiZmlsZUlkIjoiMzg3NzAyMzA2OTMxNjQyNjkzIiwiY3VycmVudFRpbWVTdGFtcCI6MTY2NTcxNTg5NSwiZXhwaXJlVGltZVN0YW1wIjoxNjY1ODAyMjk0LCJwY2ZnIjoiYmFzaWNEcm1QcmVzZXQiLCJ1cmxBY2Nlc3NJbmZvIjp7InQiOiIxNjY1ODAyMjk0In19.ouG9C8tAmF06loWZRCjOW3BuiJ5Lo6wlH0jyoY8R3pE",
            // });
            //             });

            // this.playerOptions2.sources = [
            //   {
            //     type: "application/x-mpegURL",
            //     fileID: res.data.deliveryUrl,
            //     src: "",
            //   },
            // ];
            //alert(res.data.uri == null ? video.videoUrl : res.data.uri)
            // this.playerOptions2.sources = [
            //   {
            //     type: type,
            //     src: res.data.uri == null ? video.videoUrl : res.data.uri,
            //   },
            // ];

            // adapter.on(TcAdapter.TcAdapterEvents.HLSREADY, function () {
            //   const hls = adapter.hls;
            //   // ...
            // });
            this.playerOptions2.sources = [
              {
                type: type,
                src: res.data.uri == null ? video.videoUrl : res.data.uri,
                fileID: res.data.deliveryUrl,
              },
            ];
          } else {
            this.playerOptions2.sources = [
              {
                type: type,
                src: res.data.uri == null ? video.videoUrl : res.data.uri,
              },
            ];
          }
        } else {
          this.$baseMessage(res.data.msg, "error", "vab-hey-message-error");
        }
      });
      // this.playerOptions2.sources = [{ type: type, src: url }];
    },
    showVideoDocument() {
      if (this.isFullscreen) {
        //不显示视频文稿
        //alert("不显示视频文稿");
      } else {
        this.isShowVideoDocument = !this.isShowVideoDocument;
        //alert("显示视频文稿");
      }
    },
    setDrag() {
      var videoPlayer2Obj = document.getElementById("videoPlayer2");
      var drag = videoPlayer2Obj.getAttribute("drag");
      if (drag === "false") {
        document.getElementById("videoPlayer2Div").style.position = "sticky";
        videoPlayer2Obj.setAttribute("drag", "true");
      } else {
        videoPlayer2Obj.setAttribute("drag", "false");
        document.getElementById("videoPlayer2Div").style.position = "static";
        videoPlayer2Obj.style.top = this.videoPlayer2Top + "px";
        videoPlayer2Obj.style.left = this.videoPlayer2Left + "px";
      }
    },
    closePlay() {
      if (
        this.$refs.videoPlayer1 &&
        this.$refs.videoPlayer1.player &&
        this.$refs.videoPlayer1.player.hasStarted_
      ) {
        this.$refs.videoPlayer1.player.pause();
      }
      if (
        this.$refs.videoPlayer2 &&
        this.$refs.videoPlayer2.player &&
        this.$refs.videoPlayer2.player.hasStarted_
      ) {
        this.$refs.videoPlayer2.player.pause();
      }
    },
    isArr(arr1, arr2) {
      return JSON.stringify(arr1.sort()) === JSON.stringify(arr2.sort());
    },
    singleChoiceAnswerSubmit(question) {
      let choicesLength = question.choices.length;
      let lable = "";
      //单选题
      for (let k = 0; k < choicesLength; k++) {
        if (question.choices[k].choice) {
          lable = question.choices[k].lable;
          break;
        }
      }
      let value = false;
      if (lable == question.answer) {
        value = true;
      }
      question.isAnswerTrue = value;
    },
    multipleChoiceAnswerSubmit(question) {
      let choicesLength = question.choices.length;
      let lable = [];
      for (let k = 0; k < choicesLength; k++) {
        if (question.choices[k].choice) {
          lable.push(question.choices[k].lable);
        }
      }
      let value = false;
      if (this.isArr(lable, question.answer)) {
        value = true;
      }
      question.isAnswerTrue = value;
    },
    gapFillingOrEssayAnswerSubmit(question) {
      let fieldsLength = question.fields.length;
      let value = true;
      for (let k = 0; k < fieldsLength; k++) {
        if (question.fields[k].option != question.fields[k].answer) {
          value = false;
        }
      }
      question.isAnswerTrue = value;
    },
    trueOrFalseAnswerSubmit(question) {
      let value = false;
      if (question.answer == question.choices[0].choice) {
        value = true;
      }
      question.isAnswerTrue = value;
    },
    accountingAnswerSubmit(question) {
      let value = true;
      let accounts = question.accounts;
      let accountsLength = accounts.length;
      for (let l = 0; l < accountsLength; l++) {
        let account = question.accounts[l];
        let accountEntriesLength = account.accountEntries.length;
        if (account.name != "") {
          if (account.answer != account.name) {
            value = false;
          }
        }
        for (let m = 0; m < accountEntriesLength; m++) {
          let accountEntrieData = account.accountEntries[m];
          if (
            accountEntrieData.accountEntriesNames.length > 0 &&
            accountEntrieData.name !=
              accountEntrieData.accountEntriesNames[m].name
          ) {
            value = false;
          } else if (accountEntrieData.accountEntriesNames.length < 0) {
            value = false;
          }
          if (
            accountEntrieData.type == "DEBIT" &&
            accountEntrieData.accountEntriesValues.length < 0
          ) {
            value = false;
          } else if (
            accountEntrieData.type == "DEBIT" &&
            accountEntrieData.accountEntriesValues.length > 0
          ) {
            if (
              accountEntrieData.value !=
              accountEntrieData.accountEntriesValues[0].name
            ) {
              value = false;
            }
          } else if (
            accountEntrieData.type == "CREDIT" &&
            accountEntrieData.accountEntriesTypes.length < 0
          ) {
            value = false;
          } else if (
            accountEntrieData.type == "CREDIT" &&
            accountEntrieData.accountEntriesTypes.length > 0
          ) {
            if (
              accountEntrieData.value !=
              accountEntrieData.accountEntriesTypes[0].name
            ) {
              value = false;
            }
          }
        }
      }
      question.isAnswerTrue = value;
    },
    //这里可以用来判断正确率
    setTopicAnswers(practiceIndex, topicIndex, topicAnswers) {
      if (practiceIndex >= 0) {
        //表示
        this.practiceAnswers[practiceIndex][topicIndex] = topicAnswers;
      } else {
        this.quizAnswers[topicIndex] = topicAnswers;
      }
      //console.log("topicIndex:" + topicIndex + ",practiceAnswers:"+JSON.stringify( this.practiceAnswers))
      //console.log("quizAnswers:"+JSON.stringify(this.quizAnswers))
    },
    setPracticeItemAnswerSubmit(practiceItem) {
      this.$set(practiceItem, "isAnswerSubmit", false);
      return "";
    },
    practiceAnswerSubmit(practiceIndex, practiceItem) {
      if (this.refKind == "") {
        this.$baseMessage(
          "没有数据，无法提交!",
          "warning",
          "vab-hey-message-warning"
        );
        return;
      }
      let scores = 0;
      let answersLength = this.practiceAnswers[practiceIndex].length;
      for (let i = 0; i < answersLength; i++) {
        scores += this.practiceAnswers[practiceIndex][i].scores;
      }
      let spend = (new Date().getTime() - this.answerReportStartTime) / 1000;
      practiceItem.isAnswerSubmit = true;
      //this.$set(practiceItem, "isAnswerSubmit", true)
      let answerReport = {
        refId: this.segmentDetailData.practices[practiceIndex].id,
        refKind: "Practice",
        courseId: this.courseId,
        scores: scores,
        spend: spend,
        segmentId: this.segmentDetailData.id,
        topicAnswers: this.practiceAnswers[practiceIndex],
        wrongTopicRefKind: "Exam",
        wrongTopicRefId: this.courseId,
        chapterId:
          this.courseInfo.children[this.coursePartIndex].children[
            this.courseChapterIndex
          ].id,
        sectionId:
          this.courseInfo.children[this.coursePartIndex].children[
            this.courseChapterIndex
          ].children[this.courseSectionIndex].id,
      };
      //this.practiceAnswers[practiceIndex]
      practiceTopicResultSubmit(answerReport).then((res) => {
        if (res.code == 20000) {
          this.$baseMessage("提交成功", "success", "vab-hey-message-success");
          this.refreshTree(-1, -1);
        } else {
          this.$baseMessage(res.msg, "error", "vab-hey-message-error");
          practiceItem.isAnswerSubmit = false;
        }
      });
      if (practiceIndex == this.segmentDetailData.practices.length - 1) {
        this.postSegmentsProgress();
      }
    },
    resetPracticeAnswerSubmit(practiceIndex, practiceItem) {
      (practiceItem.isAnswerSubmit = false),
        this.$refs.topic[practiceIndex].resetAnswer();
    },
    postSegmentsProgress() {
      let spend = (new Date().getTime() - this.answerReportStartTime) / 1000;
      // if (this.segmentDetailData == null) {
      //   return;
      // }
      postSegmentsProgress({
        courseId: this.courseId,
        segmentId: this.segmentDetailData.id,
        spend: spend,
      }).then((res) => {
        if (res.code == 20000) {
          this.refreshTree(-1, -1);
        }
      });
    },
    answerSubmit() {
      if (this.refKind == "") {
        this.$baseMessage(
          "没有数据，无法提交!",
          "warning",
          "vab-hey-message-warning"
        );
        return;
      }
      window.clearTimeout(this.countdownTimeout);
      let scores = 0;
      let answersLength = this.quizAnswers.length;
      for (let i = 0; i < answersLength; i++) {
        scores += this.quizAnswers[i].scores;
      }
      let spend = (new Date().getTime() - this.answerReportStartTime) / 1000;
      this.isAnswerSubmit = true;
      var chapterId =
        this.courseInfo.children[this.coursePartIndex].children[
          this.courseChapterIndex
        ].id;
      var sectionId =
        this.courseInfo.children[this.coursePartIndex].children[
          this.courseChapterIndex
        ].children[this.courseSectionIndex].id;
      let answerReport = {
        refId: this.segmentDetailData.quiz.segmentId,
        refKind: "Quiz",
        courseId: this.courseId,
        scores: scores,
        spend: spend,
        topicAnswers: this.quizAnswers,
        segmentId: this.segmentDetailData.id,
        wrongTopicRefKind: "Exam",
        wrongTopicRefId: this.courseId,
        chapterId: chapterId,
        sectionId: sectionId,
      };
      this.isAnswerSubmit = true;
      //this.quizAnswers
      quizTopicResultSubmit(answerReport).then((res) => {
        //examTopicResultSubmit
        if (res.code == 20000) {
          this.$baseMessage("提交成功", "success", "vab-hey-message-success");
          this.$router.push({
            path: "/TitleReport1/" + res.data,
            query: {
              segmentId: this.segmentDetailData.id,
              keyword: this.$refs.navInfo.keyword,
              courseId: this.courseId,
              chapterId: chapterId,
              sectionId: sectionId,
            },
          });
        } else {
          this.$baseMessage(res.msg, "error", "vab-hey-message-error");
          this.isAnswerSubmit = false;
        }
      });
      this.postSegmentsProgress();
    },
    prePage() {
      if (this.courseListIndex == 0) {
        return;
      }
      if (this.courseListData[this.courseListIndex].kind == "SEGMENT") {
        if (this.action == null) {
          this.postSegmentsProgress();
        }
      }
      this.courseListIndex--;
      this.coursesClick();
    },
    nextPage() {
      if (this.courseListIndex == this.courseListData.length - 1) {
        return;
      }
      if (this.courseListData[this.courseListIndex].kind == "SEGMENT") {
        if (!this.fileShow) {
          if (
            this.segmentDetailData != null &&
            this.segmentDetailData.quiz != null
          ) {
            let answersLength = this.quizAnswers.length;
            let correctCount = 0,
              count = 0;
            for (let i = 0; i < answersLength; i++) {
              let answersLength = this.quizAnswers[i].answers.length;
              for (let j = 0; j < answersLength; j++) {
                if (this.quizAnswers[i].answers[j].ret == "CORRECT") {
                  correctCount++;
                }
                count++;
              }
            }
            this.correctRate = Math.ceil((correctCount / count) * 100);
            this.fileShow = true;
            return;
          } else if (
            this.segmentDetailData != null &&
            this.segmentDetailData.practices != null &&
            this.segmentDetailData.practices.length > 0
          ) {
            this.fileShow = true;
            let practiceAnswersLength = this.practiceAnswers.length;
            let count = 0,
              correctCount = 0;
            for (let i = 0; i < practiceAnswersLength; i++) {
              let topicAnswers = this.practiceAnswers[i].length;
              for (let j = 0; j < topicAnswers; j++) {
                let answersLength = this.practiceAnswers[i][j].answers.length;
                for (let k = 0; k < answersLength; k++) {
                  if (this.practiceAnswers[i][j].answers[k].ret == "CORRECT") {
                    correctCount++;
                  }
                  count++;
                }
              }
              this.correctRate = Math.ceil((correctCount / count) * 100);
            }
            //this.practiceAnswers[practiceIndex][topicIndex] = topicAnswers;
            return;
          }
        }
        if (this.action == null) {
          this.postSegmentsProgress();
        }
        this.fileShow = false;
      }
      this.courseListIndex++;
      this.coursesClick();
    },
    coursesClick() {
      if (this.courseListIndex == 0) {
        this.$refs.navInfo.search();
        return;
      }
      var courseData = this.courseInfo.children;
      let partLength = courseData.length;
      var listData = this.courseListData[this.courseListIndex];
      var id = listData.id;
      var kind = listData.kind;
      var partData, chapterData, sectionData, segmentData;
      for (let i = 0; i < partLength; i++) {
        partData = courseData[i];
        //篇
        if (id == partData.id && kind == partData.kind) {
          this.$refs.navInfo.coursePartClickFn(i, partData);
          return;
        }
        let chapterLength = partData.children.length;
        for (let j = 0; j < chapterLength; j++) {
          chapterData = partData.children[j];
          if (id == chapterData.id && kind == chapterData.kind) {
            this.$refs.navInfo.courseChapterClickFn(i, j, chapterData);
            return;
          }
          //章
          let sectionLength = chapterData.children.length;
          for (let k = 0; k < sectionLength; k++) {
            sectionData = chapterData.children[k];
            if (id == sectionData.id && kind == sectionData.kind) {
              this.$refs.navInfo.courseSectionClickFn(i, j, k, sectionData);
              return;
            }
            //节
            let segmentLength = sectionData.children.length;
            for (let l = 0; l < segmentLength; l++) {
              segmentData = sectionData.children[l];
              if (id == segmentData.id && kind == segmentData.kind) {
                this.$refs.navInfo.courseSegmentClickFn(
                  i,
                  j,
                  k,
                  l,
                  segmentData,
                  sectionData
                );
                return;
              }
            }
          }
        }
      }
    },
    //选项卡跳转到详情
    handleItemClick(item, index) {
      if (this.type == "COURSE") {
        if (this.isSearch) {
          let length = this.courseInfo.children.length;
          for (let i = 0; i < length; i++) {
            if (this.courseInfo.children[i].id == item.id) {
              index = i;
              break;
            }
          }
        }
        this.$refs.navInfo.coursePartClickFn(
          index,
          this.courseInfo.children[index]
        );
      } else if (this.type == "PART") {
        if (this.isSearch) {
          let length =
            this.courseInfo.children[this.coursePartIndex].children.length;
          for (let i = 0; i < length; i++) {
            if (
              this.courseInfo.children[this.coursePartIndex].children[i].id ==
              item.id
            ) {
              index = i;
              break;
            }
          }
        }
        this.$refs.navInfo.courseChapterClickFn(
          this.coursePartIndex,
          index,
          this.courseInfo.children[this.coursePartIndex].children[index]
        );
      } else if (this.type == "CHAPTER") {
        if (this.isSearch) {
          let length =
            this.courseInfo.children[this.coursePartIndex].children[
              this.courseChapterIndex
            ].children.length;
          for (let i = 0; i < length; i++) {
            if (
              this.courseInfo.children[this.coursePartIndex].children[
                this.courseChapterIndex
              ].children[i].id == item.id
            ) {
              index = i;
              break;
            }
          }
        }
        this.$refs.navInfo.courseSectionClickFn(
          this.coursePartIndex,
          this.courseChapterIndex,
          index,
          this.courseInfo.children[this.coursePartIndex].children[
            this.courseChapterIndex
          ].children[index]
        );
      } else if (this.type == "SECTION") {
        var section =
          this.courseInfo.children[this.coursePartIndex].children[
            this.courseChapterIndex
          ].children[this.courseSectionIndex];
        if ((!section.valid || section.valid == null) && section.price != 0) {
          // this.$baseMessage(
          //   "您需要订购后才能查看详细内容哦!",
          //   "warning",
          //   "vab-hey-message-warning"
          // );
          this.payTip();
          return false;
        }
        if (this.isSearch) {
          let length = section.length;
          for (let i = 0; i < length; i++) {
            if (section.children[i].id == item.id) {
              index = i;
              break;
            }
          }
        }
        this.$refs.navInfo.courseSegmentClickFn(
          this.coursePartIndex,
          this.courseChapterIndex,
          this.courseSectionIndex,
          index,
          this.courseInfo.children[this.coursePartIndex].children[
            this.courseChapterIndex
          ].children[this.courseSectionIndex].children[index],
          this.courseInfo.children[this.coursePartIndex].children[
            this.courseChapterIndex
          ].children[this.courseSectionIndex]
        );
      }
    },
    payTip() {
      this.$confirm("您需要订购后才能查看详细内容哦,是否去订购?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.buyBouquet();
        })
        .catch(() => {});
    },
    getCourseId() {
      let id = null; //sessionStorage.getItem("COURSE_ID");
      const key = "COURSE_ID";
      if (this.$cookies.isKey(key)) {
        id = this.$cookies.get(key);
      }
      return id;
    },
  },
};
</script>

<style lang="scss" scoped>
.SubjectMain {
  position: relative;
  .SubjectMainRight {
    padding-left: 410px;
    .SubjectCenter {
      width: 700px;
      margin: 0px auto;
      padding: 75px 0 15px 0;
      .SubjectCenter-Title {
        font-size: 36px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 0px;
        color: #060606;
        line-height: 50px;
        .TestPaperCenter-time {
          float: right;
          line-height: 40px;
          font-size: 16px;
          span {
            color: #8d0005;
          }
        }
      }
      .SubjectCenter-Sub-Title {
        display: flex;
        flex-direction: column;
        font-size: 26px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 0px;
        color: #060606;
        line-height: 40px;
        margin-top: 10px;
      }
      .SubjectCenter-Sub-Foreword {
        margin-top: 15px;
      }

      .w164jian {
        position: fixed;
        //position: sticky;
        top: 0;
      }
      .SubjectCenter-video {
        margin-top: 15px;
        video {
          height: 450px;
          width: 100%;
          background: #000000;
        }
      }
      .Subject-lh {
        height: 50px;
        line-height: 50px;
        text-align: right;
        font-size: 16px;
        color: #060606;
        margin-top: 10px;
        span {
          color: #8d0005;
        }
      }
      .Subject-title {
        height: 30px;
        margin-top: 20px;
        .Subject-title-fl {
          font-size: 28px;
          font-weight: bold;
          color: #575b67;
          float: left;
          line-height: 30px;
        }
        .Subject-title-fr {
          float: right;
          height: 30px;
          background: #636363;
          line-height: 30px;
          border-radius: 16px;
          padding: 0 10px;
          i {
            margin-top: 3px;
            float: left;
          }
          span {
            color: #ffffff;
            font-size: 12px;
            font-weight: normal;
            margin-left: 5px;
            float: left;
          }
        }
      }
      .Subject-topicAsk {
        margin-top: 15px;
        font-size: 16px;
        font-weight: 400;
        color: #060606;
      }
    }
    .containerCenter {
      width: 700px;
      margin: 0px auto;
      padding: 75px 0 15px 0;
      .containerCenter-Title {
        font-size: 36px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 0px;
        color: #060606;
        line-height: 50px;
      }
      .containerCenter-video {
        margin-top: 15px;
        video {
          height: 450px;
          width: 100%;
          background: #000000;
        }
      }
      .containerCenter-Title-1 {
        line-height: 40px;
        font-size: 28px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #575b67;

        margin-top: 30px;
      }
      .containerCenter-Font {
        margin-top: 30px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 24px;
        color: #060606;
      }
      .containerCenter-Ul {
        margin-top: 30px;
        li {
          position: relative;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 32px;
          color: #575a63;

          padding-left: 15px;
          &::after {
            display: block;
            width: 5px;
            height: 5px;
            position: absolute;
            left: 0;
            top: 13px;
            content: "";
            background: #575a63;
            border-radius: 50%;
          }
        }
      }
      .page-but {
        margin-top: 30px;
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #575a63;
      }
    }

    .containerCenterList {
      width: 700px;
      margin: 0px auto;
      padding: 75px 0 15px 0;
      .containerCenter-Title {
        font-size: 36px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 0px;
        color: #060606;
        line-height: 50px;
        .title {
          width: 524px;
          float: left;
        }
        .buyBtn {
          width: 156px;
          height: 35px;
          margin-top: 10px;
          background: #ffffff;
          border-radius: 3px 3px 3px 3px;
          opacity: 1;
          border: 1px solid #173d7c;
          float: right;
          span {
            width: 28px;
            height: 19px;
            margin-top: 8px;
            font-size: 14px;
            padding-left: 45px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #173d7c;
            line-height: 19px;
            width: 110px;
            float: left;
          }
          i {
            //width: 40px;
            margin-top: 2px;
            float: left;
            line-height: 24px;
          }
        }
      }
      dl {
        padding: 0;
        list-style: none;
        margin: 0 auto;
        &::after {
          display: block;
          clear: both;
          content: "";
        }
        dd {
          padding: 0;
          list-style: none;
          margin: 0 auto;
          width: calc(33.333333% - 28px);
          //min-height: 228px;
          height: 208px;
          //max-height: 328px;
          background: #ffffff;
          border-radius: 0px 0px 0px 0px;
          opacity: 1;
          //border: 1px solid #aeaeae;
          cursor: pointer;
          float: left;
          margin-top: 30px;
          margin-right: 27px;
          &:nth-child(3n) {
            margin-right: 0;
          }
          .itemimg {
            img {
              width: 100%;
              //height: 150px;
            }
          }
          .contertp30 {
            //padding: 5px 30px 20px 30px;
            .infotitle {
              //text-align: center;
              // font-size: 16px;
              // font-weight: 800;
              // color: #575b67;
              // line-height: 22px;
              // max-height: 66px;
              // text-overflow: ellipsis;
              // overflow: hidden;
              // margin-top: 10px;
              //border-bottom: 1px solid #707070;
              padding: 0px, 5px, 0px, 5px;
              text-overflow: ellipsis;
              overflow: hidden;
              margin-top: 15px;
              max-height: 66px;
              font-size: 16px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #575b67;
              text-align: center;
              // line-height: 0px;
              // -webkit-background-clip: text;
              // -webkit-text-fill-color: transparent;
            }
            .infoFont {
              font-size: 14px;
              font-weight: 400;
              color: #666666;
              line-height: 20px;
              padding-top: 8px;
              max-height: 40px;
              overflow: hidden;
            }
            .infoButton {
              width: 138px;
              height: 38px;
              background: #777777;
              border-radius: 16px;
              border: 1px solid #5e5e5e;
              line-height: 38px;
              text-align: center;
              font-size: 16px;
              color: #ffffff;
              margin: 15px auto 0 auto;
              cursor: pointer;
              &.on {
                background: #173d7c;
                border: 1px solid #173d7c;
              }
            }
          }
        }
      }
    }

    .NewTopicShow {
      background: rgba($color: #000000, $alpha: 0.6);
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100vh;
      box-sizing: border-box;
      padding: 80px 30px 20px 30px;
      .NewTopicInfo {
        border-radius: 6px;
        background: #ffffff;
        width: 830px;
        margin: 0 auto;
        box-sizing: border-box;
        position: relative;
        padding-top: 35px;
        padding-bottom: 35px;
        .infoTitle {
          margin: 0 35px;
          padding: 15px 20px;
          background: #173d7c;
          color: #ffffff;
          border-radius: 6px;
          text-align: center;
          .title {
            font-size: 24px;
            font-weight: bold;
            color: #ffffff;
            line-height: 50px;
          }
          .title-font {
            font-size: 16px;
            font-weight: 400;
            color: #ffffff;
            padding-bottom: 10px;
          }
        }
        .NewTopic-ul {
          padding-top: 30px;
          ul {
            li {
              padding: 12px 30px;
              &::after {
                clear: both;
                display: block;
                content: "";
              }
              &:hover {
                background: #f2f2f2;
              }
            }
          }
        }
        .close {
          border-radius: 50%;
          width: 24px;
          height: 24px;
          background: url("~@/assets/icon-gb.png") no-repeat center;
          background-size: cover;
          cursor: pointer;
          position: absolute;
          right: 10px;
          top: 10px;
        }
      }
    }

    .NewPayShow {
      background: rgba($color: #000000, $alpha: 0.6);
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100vh;
      box-sizing: border-box;
      padding: 80px 30px 20px 30px;
      .NewTopicInfo {
        border-radius: 6px;
        background: #ffffff;
        width: 830px;
        margin: 0 auto;
        box-sizing: border-box;
        position: relative;
        padding-top: 35px;
        padding-bottom: 35px;
        .infoTitle {
          margin: 0 35px;
          padding: 15px 20px;
          background: #ffcf00;
          color: #000;
          border-radius: 6px;
          text-align: center;
          .title {
            font-size: 24px;
            font-weight: bold;
            color: #000;
            line-height: 50px;
          }
          .title-font {
            font-size: 16px;
            font-weight: 400;
            color: #000;
            padding-bottom: 10px;
          }
        }
        .warBr {
          border: 1px solid #cccccc;
          padding: 0px;
          margin-top: 20px;
          text-align: center;
          width: 40%;
          img {
            width: 100%;
          }
        }
        .warBr-title {
          text-align: center;
          line-height: 36px;
        }
        .NewTopic-ul {
          padding-top: 30px;
          ul {
            li {
              padding: 12px 30px;
              &::after {
                clear: both;
                display: block;
                content: "";
              }
              &:hover {
                background: #f2f2f2;
              }
            }
          }
        }
        .close {
          border-radius: 50%;
          width: 24px;
          height: 24px;
          background: url("~@/assets/icon-gb.png") no-repeat center;
          background-size: cover;
          cursor: pointer;
          position: absolute;
          right: 10px;
          top: 10px;
        }
      }
    }

    .gmBut {
      height: 50px;
      background: #173d7c;
      border-radius: 10px;
      font-size: 16px;
      font-weight: 400;
      color: #ffffff;
      text-align: center;
      cursor: pointer;
      line-height: 50px;
    }
    .lh50 {
      line-height: 50px;
      font-size: 16px;
      font-weight: bold;
      color: #ff4600;
      span {
        height: 16px;
        font-size: 12px;
        font-weight: 400;
        color: #666666;
        margin-left: 15px;
        font-weight: normal;
      }
    }
    .font-1 {
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      line-height: 28px;
    }
    .font-time {
      font-size: 12px;
      font-weight: 400;
      color: #666666;
    }
    .lh50bf {
      line-height: 50px;
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      input {
        float: left;
        margin-top: 20px;
        margin-right: 10px;
      }
    }
    .borC {
      border-top-left-radius: 0 !important;
    }
    .tasBut {
      float: left;
      span {
        cursor: pointer;
        width: 24px;
        height: 24px;
        float: left;
        line-height: 24px;
        text-align: center;
        margin-top: 13px;
      }
    }
  }
  .SubjectMainRightList {
    padding-left: 410px;
    .containerCenter {
      width: 700px;
      margin: 0px auto;
      padding: 75px 0 15px 0;
    }
  }
}
.EduHome {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  footer {
    padding: 24px 40px 40px;
    font-size: 16px;
    color: $color-text-6;
    background-color: $color-background-5;
  }
}
.Subject-or-title {
  line-height: 30px;
  padding: 20px 0 5px 0;
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}
.Subject-or {
  margin-top: 10px;
  border: 1px solid #d6d6d6;
  opacity: 1;
  border-radius: 6px;
  dl {
    dd {
      .Subject-orDdTitle {
        padding: 20px 14px;
        label {
          cursor: pointer;
          display: block;
          position: relative;
          input {
            float: left;
            margin-top: 6px;
          }
          div {
            padding-left: 35px;
            padding-right: 35px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 24px;
            color: #060606;
            opacity: 1;
          }
        }
        i {
          position: absolute;
          right: 0px;
          top: 5px;
        }
      }
      .Subject-info {
        background: #e8e8e8;
        opacity: 1;
        border-radius: 0px;
        padding: 20px 20px 20px 50px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 24px;
        color: #060606;
        opacity: 1;
      }
    }
  }
}
.Subject-If {
  height: 68px;
  background: #dfe9df;
  border: 1px solid #307a35;
  border-radius: 6px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 24px;
  color: #060606;
  opacity: 1;
  padding: 0 14px;
  margin-top: 20px;
  i {
    float: left;
    margin-top: 20px;
  }
  span {
    float: left;
    margin-left: 18px;
    margin-top: 22px;
  }
}
.Subject-explain {
  background: #ffffff;
  border: 1px solid #aeaeae;
  opacity: 1;
  border-radius: 6px;
  padding: 15px 20px;
  margin-top: 20px;
  .explainTitle {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #636363;
    opacity: 1;
    padding-left: 30px;
    background: url("~@/assets/outline/iconDown.png") no-repeat left;
    background-size: 16px 17px;
    line-height: 36px;
  }
  .explainCenter {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #636363;
    opacity: 1;
    line-height: 24px;
    margin-top: 20px;
  }
  &.on {
    background: #ede2e3;
    border: 1px solid #8d0005;
    opacity: 1;
    border-radius: 6px;
    .explainTitle {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #060606;
      opacity: 1;
      background: url("~@/assets/outline/naviconitem-3.png") no-repeat left;
    }
    .wrongReason {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #060606;
      opacity: 1;
      margin-top: 20px;
      padding-left: 30px;
    }
    .explainCenter {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 24px;
      color: #060606;
      opacity: 1;
      padding-left: 30px;
      a:link,
      a:visited {
        color: #8d0005;
      }
    }
  }
}
.Subject-ul {
  margin-top: 15px;
  li {
    padding: 10px 0;
    font-size: 16px;
    font-weight: 400;
    color: #060606;
    .titleFl {
      padding-right: 120px;
    }
    .titleFr {
      text-align: right;
      float: right;
      label {
        float: left;
        margin-left: 15px;
        input {
          float: left;
        }
        span {
          float: left;
          margin-left: 5px;
        }
      }
    }
    &.on {
      .titleFr {
        color: #173d7c;
      }
    }
    &.on1 {
      .titleFr {
        color: #307a35;
      }
    }
    &.on2 {
      .titleFr {
        color: #8d0005;
      }
    }
    &::after {
      clear: both;
      display: block;
      content: "";
    }
  }
}
.Subject-or-text {
  padding-top: 10px;
  textarea {
    padding: 5px 10px;
    border: 1px solid #707070;
    height: 350px;
    line-height: 24px;
    font-size: 16px;
    width: 100%;
  }
}
.Subject-bor {
  background: #ffffff;
  border-radius: 6px 6px 6px 6px;
  border: 1px solid #aeaeae;
  margin-top: 20px;
  padding: 20px;
  font-size: 16px;
  font-weight: 400;
  color: #636363;
  .Subject-pl36 {
    padding-left: 30px;
    background: url("~@/assets/WrongQuestion/iconsx1.png") no-repeat left;
  }
  .Subject-borMt15 {
    margin-top: 15px;
    padding-left: 30px;
  }
}
p {
  margin: 0;
}
.outlineBut {
  padding: 30px 0;
  text-align: center;
  button {
    height: 44px;
    opacity: 1;
    border-radius: 8px;
    padding: 0 20px;
    margin: 0 10px;
    cursor: pointer;
    color: #ffffff;
    border: 0;
    &.but1 {
      background: #5a5a5d;
    }
    &.but2 {
      background: #173d7c;
    }
  }
}
.nextPage {
  //padding: 30px 0;
  text-align: right;
  width: 700px;
  margin: 30px auto;
  button {
    height: 34px;
    opacity: 1;
    border-radius: 8px;
    padding: 0 20px;
    //margin: 0 10px;
    cursor: pointer;
    color: #ffffff;
    border: 0;
    &.but1 {
      background: #5a5a5d;
    }
    &.but2 {
      background: #173d7c;
      margin-left: 20px;
    }
  }
}
.fllh36 {
  float: left;
  line-height: 45px;
  width: 30px;
  text-align: center;
}
.optionInfo {
  padding-left: 30px;
  input {
    height: 45px;
    overflow: hidden;
    box-sizing: border-box;
    width: 100%;
    padding: 0 12px;
    font-size: 14px;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #989898;
  }
}
.mt15 {
  margin-top: 15px;
}
.lhpbtitle {
  font-weight: bold;
  line-height: 32px;
}
.itemInput input {
  height: 45px;
  overflow: hidden;
  box-sizing: border-box;
  width: 100%;
  padding: 0 12px;
  font-size: 14px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #989898;
}
.SDdTitle {
  padding: 15px 0;
  label {
    float: left;
    cursor: pointer;
    display: block;
    position: relative;
    input {
      float: left;
      margin-top: 6px;
    }
    div {
      padding-left: 35px;
      padding-right: 35px;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #060606;
    }
  }
  i {
    position: absolute;
    right: 0px;
    top: 5px;
  }
  textarea {
    width: 100%;
    height: 102px;
    background: #ffffff;
    border-radius: 3px 3px 3px 3px;
    border: 1px solid #989898;
    box-sizing: border-box;
    padding: 10px 14px;
    font-size: 14px;
    line-height: 24px;
  }
}
.comprehensive {
  border: 1px solid #aeaeae;
  padding: 4px;
  margin-top: 15px;
  .comprehensive-item {
    height: 45px;
    background: #ffffff;
    box-shadow: 0px 2px 2px 1px rgba(0, 0, 0, 0.16);
    border-radius: 6px;
    border: 1px solid #707070;
    line-height: 45px;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    color: #636363;
    cursor: pointer;
  }
}
.lhpbtitle1 {
  height: 36px;
  font-size: 16px;
  font-weight: 400;
  color: #060606;
  line-height: 36px;
  margin-top: 15px;
}
.br6 {
  border-radius: 6px;
  overflow: hidden;
  text-align: center;
  padding: 0;
  background: #ffffff;
  .br6_header {
    height: 50px;
    background: #e8e8e8;
    border-bottom: 1px solid #aeaeae;
    text-align: center;
    line-height: 50px;
  }
}
.h300BK_top {
  display: block;
  //min-height: 214px;
  height: 49px;
  .w25bf {
    width: 25%;
    display: block;
    float: left;
    padding: 4px;
  }
  .sortable-chosen {
    width: 25%;
    left: auto;
    top: auto;
  }
}
.h300BK {
  display: block;
  //min-height: 210px;
  height: 49px;
  position: relative;
  margin: 4px;
  // &:hover {
  //   background-color: #dddddd;
  //   border-radius: 6px;
  // }
  border: 1px dashed rgb(112, 112, 112);
  border-radius: 6px;
  .w25bf {
    margin: -1px;
    padding: 0px;
  }
}
.purchaseItem {
  background: #f3f3f3;
  border-radius: 6px;
  border: 1px solid #707070;
  background-color: #f3f3f3;
  margin-top: 20px;
  padding: 18px 50px;
  table {
    text-align: center;
    width: 100%;
  }
  .purchaseItemTitle {
    font-size: 16px;
    font-weight: 400;
    color: #333333;
  }
  .purchaseItemTime {
    font-size: 12px;
    font-weight: 400;
    color: #666666;
    line-height: 24px;
  }
  .purchaseItemMoney {
    font-size: 16px;
    font-weight: bold;
    color: #ff9100;
    span {
      font-size: 12px;
      font-weight: 400;
      color: #666666;
      margin-left: 10px;
    }
  }
  .purchaseItemButton {
    cursor: pointer;
    width: 150px;
    height: 50px;
    background: #173d7c;
    border-radius: 10px;
    text-align: center;
    line-height: 50px;
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
  }
}
</style>
<style>
.itemSpan p,
.inWor p {
  display: inline;
  white-space: pre-line;
}
.SorDdTitle .el-radio__input,
.SorDdTitle .el-checkbox__input {
  float: left;
  margin-top: 2px;
}
.SorDdTitle .el-radio__label,
.SorDdTitle .el-checkbox__label {
  display: block;
  padding-left: 20px;
  line-height: 20px;
}
.showFile {
  background: rgba(67, 67, 67, 0.8);
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  box-sizing: border-box;
  padding: 0 50px;
  z-index: 999;
}
.showFile-info {
  width: 630px;
  height: 380px;
  background: #ffffff;
  border-radius: 5px;
  margin: 0 auto;
  position: relative;
  top: 50%;
  margin-top: -210px;
}
.showFile-info i {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
}
.showFile-info i img {
  cursor: pointer;
  width: 24px;
  height: 24px;
}
.showFile-info-font {
  text-align: center;
  font-size: 24px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  line-height: 36px;
  padding: 75px 30px 20px 30px;
}
.showFile-info-font span {
  color: #ff6600;
}
.showFile-info-button {
  width: 344px;
  height: 50px;
  background: #173d7c;
  border-radius: 6px;
  margin: 0 auto;
  line-height: 50px;
  font-size: 16px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  cursor: pointer;
  text-align: center;
}
.showFile-info-button:hover {
  background: #10408f;
}
.showFile-info-h {
  font-size: 16px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  margin-top: 20px;
  text-align: center;
}
.showFile-info-h span {
  border-bottom: 1px solid #666666;
  display: inline-block;
  cursor: pointer;
  line-height: 20px;
}
</style>
