import request from "@/utils/request";
const BASE_PATH = "/front/textbook/";

export function getSegmentDetailsById(id) {
  return request({
    url: BASE_PATH + "segment/" + id + "/details",
    method: "get",
  });
}

export function getSegmentColdcall(id) {
  return request({
    url: BASE_PATH + "segment/" + id + "/coldcall",
    method: "get",
  });
}

export function getSegmentListBySectionId(sectionId) {
  return request({
    url: BASE_PATH + "segment/section/" + sectionId,
    method: "get",
  });
}
