import request from "@/utils/request";
const BASE_PATH = "/front/textbook/";

export function getSectionDetailsById(id) {
  return request({
    url: BASE_PATH + "section/" + id + "/details",
    method: "get",
  });
}

export function getSectionRecommend(data) {
  return request({
    url: BASE_PATH + "section/recommend",
    method: "post",
    data
  });
}

export function getSectionListByChapterId(chapterId) {
  return request({
    url: BASE_PATH + "section/chapter/" + chapterId,
    method: "get"
  });
}
