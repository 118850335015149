import request from "@/utils/request";

const BASE_PATH = "/front/ground/progress/segments";
export function postSegmentsVideoProgress(data) {
  return request({
    url: BASE_PATH + "/video",
    method: "post",
    data,
  });
}

export function postSegmentsProgress(data) {
  return request({
    url: BASE_PATH,
    method: "post",
    data,
  });
}
