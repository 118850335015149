<template>
  <div class="EduHome">
    <div class="ColdCallShow">
      <div class="ColdCallInfo">
        <div class="icon-gb" v-if="isAnswerSubmit" @click="close">
          <img src="~@/assets/icon-gb.png" />
        </div>
        <div class="ColdCallInfo-title">冷呼叫</div>
        <div class="qTitle" v-if="type == 1">
          <div class="q-icon"><img src="~@/assets/ColdCall/q-icon.png" /></div>
          <div
            class="qTitle-font"
            v-html="coldCallData.topic.questions[0].content"
          ></div>
        </div>
        <!-- <div class="ColdCallInfoTitle1">题目</div>
        <div class="ColdCallInfoFont">判断甲公司的会计处理是否正确，并说明理由，对于甲公司不正确的会计处理，请编制更正2013年度财务报表相关项目的会计分录。</div>
        <div class="ColdCallInfoFz16">你觉得这个是正确的吗？请说明你的理由。</div>
        <div class="ColdCallInfoTitle2">答：</div> -->
        <div v-if="type == 0">
          <topic
            :topicData="coldCallData.topic"
            presentation="TILL_ALL"
            refKind="ColdCall"
            :refId="coldCallData.id"
            @set-topic-answers="setTopicAnswers"
            :isAnswerSubmit="isAnswerSubmit"
            :isShowSubjecTitle="false"
          />
          <!-- <div class="ColdCallInfoTx">
            <textarea v-model="answer"></textarea>
          </div> -->
          <div class="ColdCallInfoButton" @click="submitAnswer(0)">提交</div>
          <div class="ColdCallInfoTime">
            倒计时：<span>{{ countdown }}</span>
          </div>
        </div>

        <div v-if="type == 1" class="ColdCallInfo-mt30">
          <ul>
            <li
              v-for="(memberItem, memberIndex) in memberList"
              :key="memberIndex"
            >
              <el-tooltip v placement="bottom">
                <div slot="content" class="wbf">
                  <div class="wbf-font" v-html="memberAnswer"></div>
                  <!-- <div class="wbf-item">
                    <div class="wbf-item-fl">
                      <span>七月 29， 2021 在 01：07 下午 ET</span>
                      <span class="icon6">(0)</span>
                    </div>
                    <div class="wbf-item-fr">2</div>
                  </div> -->
                </div>
                <div class="info" @mouseover="showAnswer(memberIndex)">
                  <div class="imgInfo">
                    <img
                      v-if="
                        memberItem.avatar != '' && memberItem.avatar != null
                      "
                      width="64"
                      height="64"
                      :src="memberItem.avatar"
                    />
                    <img v-else src="~@/assets/ColdCall/31475-1.png" />
                  </div>
                  <!-- <div class="itemInfo">
                    <span>1</span>
                    <span>12</span>
                    <span class="icon-1"></span>
                    <span class="icon-2"></span>
                  </div> -->
                  <div class="itemInfoName">{{ memberItem.fullname }}</div>
                </div>
              </el-tooltip>
            </li>
            <!-- <li>
              <el-tooltip placement="bottom">
                <div slot="content" class="wbf">
                  <div class="wbf-font">
                    保守主义的主要优点是，它提供了对所涉风险的准确衡量，并帮助利益相关者看到组织的实际价值，这将有助于他们做出更好的决策，我也认为它可能有助于组织的现金流。至于缺点，它可能导致很多错失的机会，它也低估了组织的价值和灵活性。保守主义的主要优点是，它提供了对所涉风险的准确衡量，并帮助利益相关者看到组织的实际价值，这将有助于他们做出更好的决策，我也认为它可能有助于组织的现金流。
                  </div>
                  <div class="wbf-item">
                    <div class="wbf-item-fl">
                      <span>七月 29， 2021 在 01：07 下午 ET</span>
                      <span class="icon6">(0)</span>
                    </div>
                    <div class="wbf-item-fr">2</div>
                  </div>
                </div>
                <div class="info">
                  <div class="imgInfo">
                    <img src="~@/assets/ColdCall/31475-1.png" />
                  </div>
                  <div class="itemInfo">
                    <span>1</span>
                    <span>12</span>
                    <span class="icon-1"></span>
                    <span class="icon-2"></span>
                  </div>
                  <div class="itemInfoName">王嘉声</div>
                </div>
              </el-tooltip>
            </li>
            <li>
              <el-tooltip placement="bottom">
                <div slot="content" class="wbf">
                  <div class="wbf-font">
                    保守主义的主要优点是，它提供了对所涉风险的准确衡量，并帮助利益相关者看到组织的实际价值，这将有助于他们做出更好的决策，我也认为它可能有助于组织的现金流。至于缺点，它可能导致很多错失的机会，它也低估了组织的价值和灵活性。保守主义的主要优点是，它提供了对所涉风险的准确衡量，并帮助利益相关者看到组织的实际价值，这将有助于他们做出更好的决策，我也认为它可能有助于组织的现金流。
                  </div>
                  <div class="wbf-item">
                    <div class="wbf-item-fl">
                      <span>七月 29， 2021 在 01：07 下午 ET</span>
                      <span class="icon6">(0)</span>
                    </div>
                    <div class="wbf-item-fr">2</div>
                  </div>
                </div>
                <div class="info">
                  <div class="imgInfo">
                    <img src="~@/assets/ColdCall/31475-1.png" />
                  </div>
                  <div class="itemInfo">
                    <span>1</span>
                    <span>12</span>
                    <span class="icon-1"></span>
                    <span class="icon-2"></span>
                  </div>
                  <div class="itemInfoName">王嘉声</div>
                </div>
              </el-tooltip>
            </li>
            <li>
              <el-tooltip placement="bottom">
                <div slot="content" class="wbf">
                  <div class="wbf-font">
                    保守主义的主要优点是，它提供了对所涉风险的准确衡量，并帮助利益相关者看到组织的实际价值，这将有助于他们做出更好的决策，我也认为它可能有助于组织的现金流。至于缺点，它可能导致很多错失的机会，它也低估了组织的价值和灵活性。保守主义的主要优点是，它提供了对所涉风险的准确衡量，并帮助利益相关者看到组织的实际价值，这将有助于他们做出更好的决策，我也认为它可能有助于组织的现金流。
                  </div>
                  <div class="wbf-item">
                    <div class="wbf-item-fl">
                      <span>七月 29， 2021 在 01：07 下午 ET</span>
                      <span class="icon6">(0)</span>
                    </div>
                    <div class="wbf-item-fr">2</div>
                  </div>
                </div>
                <div class="info">
                  <div class="imgInfo">
                    <img src="~@/assets/ColdCall/31475-1.png" />
                  </div>
                  <div class="itemInfo">
                    <span>1</span>
                    <span>12</span>
                    <span class="icon-1"></span>
                    <span class="icon-2"></span>
                  </div>
                  <div class="itemInfoName">王嘉声</div>
                </div>
              </el-tooltip>
            </li>
            <li>
              <el-tooltip placement="bottom">
                <div slot="content" class="wbf">
                  <div class="wbf-font">
                    保守主义的主要优点是，它提供了对所涉风险的准确衡量，并帮助利益相关者看到组织的实际价值，这将有助于他们做出更好的决策，我也认为它可能有助于组织的现金流。至于缺点，它可能导致很多错失的机会，它也低估了组织的价值和灵活性。保守主义的主要优点是，它提供了对所涉风险的准确衡量，并帮助利益相关者看到组织的实际价值，这将有助于他们做出更好的决策，我也认为它可能有助于组织的现金流。
                  </div>
                  <div class="wbf-item">
                    <div class="wbf-item-fl">
                      <span>七月 29， 2021 在 01：07 下午 ET</span>
                      <span class="icon6">(0)</span>
                    </div>
                    <div class="wbf-item-fr">2</div>
                  </div>
                </div>
                <div class="info">
                  <div class="imgInfo">
                    <img src="~@/assets/ColdCall/31475-1.png" />
                  </div>
                  <div class="itemInfo">
                    <span>1</span>
                    <span>12</span>
                    <span class="icon-1"></span>
                    <span class="icon-2"></span>
                  </div>
                  <div class="itemInfoName">王嘉声</div>
                </div>
              </el-tooltip>
            </li>
            <li>
              <el-tooltip placement="bottom">
                <div slot="content" class="wbf">
                  <div class="wbf-font">
                    保守主义的主要优点是，它提供了对所涉风险的准确衡量，并帮助利益相关者看到组织的实际价值，这将有助于他们做出更好的决策，我也认为它可能有助于组织的现金流。至于缺点，它可能导致很多错失的机会，它也低估了组织的价值和灵活性。保守主义的主要优点是，它提供了对所涉风险的准确衡量，并帮助利益相关者看到组织的实际价值，这将有助于他们做出更好的决策，我也认为它可能有助于组织的现金流。
                  </div>
                  <div class="wbf-item">
                    <div class="wbf-item-fl">
                      <span>七月 29， 2021 在 01：07 下午 ET</span>
                      <span class="icon6">(0)</span>
                    </div>
                    <div class="wbf-item-fr">2</div>
                  </div>
                </div>
                <div class="info">
                  <div class="imgInfo">
                    <img src="~@/assets/ColdCall/31475-1.png" />
                  </div>
                  <div class="itemInfo">
                    <span>1</span>
                    <span>12</span>
                    <span class="icon-1"></span>
                    <span class="icon-2"></span>
                  </div>
                  <div class="itemInfoName">王嘉声</div>
                </div>
              </el-tooltip>
            </li>
            <li>
              <el-tooltip placement="bottom">
                <div slot="content" class="wbf">
                  <div class="wbf-font">
                    保守主义的主要优点是，它提供了对所涉风险的准确衡量，并帮助利益相关者看到组织的实际价值，这将有助于他们做出更好的决策，我也认为它可能有助于组织的现金流。至于缺点，它可能导致很多错失的机会，它也低估了组织的价值和灵活性。保守主义的主要优点是，它提供了对所涉风险的准确衡量，并帮助利益相关者看到组织的实际价值，这将有助于他们做出更好的决策，我也认为它可能有助于组织的现金流。
                  </div>
                  <div class="wbf-item">
                    <div class="wbf-item-fl">
                      <span>七月 29， 2021 在 01：07 下午 ET</span>
                      <span class="icon6">(0)</span>
                    </div>
                    <div class="wbf-item-fr">2</div>
                  </div>
                </div>
                <div class="info">
                  <div class="imgInfo">
                    <img src="~@/assets/ColdCall/31475-1.png" />
                  </div>
                  <div class="itemInfo">
                    <span>1</span>
                    <span>12</span>
                    <span class="icon-1"></span>
                    <span class="icon-2"></span>
                  </div>
                  <div class="itemInfoName">王嘉声</div>
                </div>
              </el-tooltip>
            </li>
            <li>
              <el-tooltip placement="bottom">
                <div slot="content" class="wbf">
                  <div class="wbf-font">
                    保守主义的主要优点是，它提供了对所涉风险的准确衡量，并帮助利益相关者看到组织的实际价值，这将有助于他们做出更好的决策，我也认为它可能有助于组织的现金流。至于缺点，它可能导致很多错失的机会，它也低估了组织的价值和灵活性。保守主义的主要优点是，它提供了对所涉风险的准确衡量，并帮助利益相关者看到组织的实际价值，这将有助于他们做出更好的决策，我也认为它可能有助于组织的现金流。
                  </div>
                  <div class="wbf-item">
                    <div class="wbf-item-fl">
                      <span>七月 29， 2021 在 01：07 下午 ET</span>
                      <span class="icon6">(0)</span>
                    </div>
                    <div class="wbf-item-fr">2</div>
                  </div>
                </div>
                <div class="info">
                  <div class="imgInfo">
                    <img src="~@/assets/ColdCall/31475-1.png" />
                  </div>
                  <div class="itemInfo">
                    <span>1</span>
                    <span>12</span>
                    <span class="icon-1"></span>
                    <span class="icon-2"></span>
                  </div>
                  <div class="itemInfoName">王嘉声</div>
                </div>
              </el-tooltip>
            </li>
            <li>
              <el-tooltip placement="bottom">
                <div slot="content" class="wbf">
                  <div class="wbf-font">
                    保守主义的主要优点是，它提供了对所涉风险的准确衡量，并帮助利益相关者看到组织的实际价值，这将有助于他们做出更好的决策，我也认为它可能有助于组织的现金流。至于缺点，它可能导致很多错失的机会，它也低估了组织的价值和灵活性。保守主义的主要优点是，它提供了对所涉风险的准确衡量，并帮助利益相关者看到组织的实际价值，这将有助于他们做出更好的决策，我也认为它可能有助于组织的现金流。
                  </div>
                  <div class="wbf-item">
                    <div class="wbf-item-fl">
                      <span>七月 29， 2021 在 01：07 下午 ET</span>
                      <span class="icon6">(0)</span>
                    </div>
                    <div class="wbf-item-fr">2</div>
                  </div>
                </div>
                <div class="info">
                  <div class="imgInfo">
                    <img src="~@/assets/ColdCall/31475-1.png" />
                  </div>
                  <div class="itemInfo">
                    <span>1</span>
                    <span>12</span>
                    <span class="icon-1"></span>
                    <span class="icon-2"></span>
                  </div>
                  <div class="itemInfoName">王嘉声</div>
                </div>
              </el-tooltip>
            </li>
            <li>
              <el-tooltip placement="bottom">
                <div slot="content" class="wbf">
                  <div class="wbf-font">
                    保守主义的主要优点是，它提供了对所涉风险的准确衡量，并帮助利益相关者看到组织的实际价值，这将有助于他们做出更好的决策，我也认为它可能有助于组织的现金流。至于缺点，它可能导致很多错失的机会，它也低估了组织的价值和灵活性。保守主义的主要优点是，它提供了对所涉风险的准确衡量，并帮助利益相关者看到组织的实际价值，这将有助于他们做出更好的决策，我也认为它可能有助于组织的现金流。
                  </div>
                  <div class="wbf-item">
                    <div class="wbf-item-fl">
                      <span>七月 29， 2021 在 01：07 下午 ET</span>
                      <span class="icon6">(0)</span>
                    </div>
                    <div class="wbf-item-fr">2</div>
                  </div>
                </div>
                <div class="info">
                  <div class="imgInfo">
                    <img src="~@/assets/ColdCall/31475-1.png" />
                  </div>
                  <div class="itemInfo">
                    <span>1</span>
                    <span>12</span>
                    <span class="icon-1"></span>
                    <span class="icon-2"></span>
                  </div>
                  <div class="itemInfoName">王嘉声</div>
                </div>
              </el-tooltip>
            </li>
            <li>
              <el-tooltip placement="bottom">
                <div slot="content" class="wbf">
                  <div class="wbf-font">
                    保守主义的主要优点是，它提供了对所涉风险的准确衡量，并帮助利益相关者看到组织的实际价值，这将有助于他们做出更好的决策，我也认为它可能有助于组织的现金流。至于缺点，它可能导致很多错失的机会，它也低估了组织的价值和灵活性。保守主义的主要优点是，它提供了对所涉风险的准确衡量，并帮助利益相关者看到组织的实际价值，这将有助于他们做出更好的决策，我也认为它可能有助于组织的现金流。
                  </div>
                  <div class="wbf-item">
                    <div class="wbf-item-fl">
                      <span>七月 29， 2021 在 01：07 下午 ET</span>
                      <span class="icon6">(0)</span>
                    </div>
                    <div class="wbf-item-fr">2</div>
                  </div>
                </div>
                <div class="info">
                  <div class="imgInfo">
                    <img src="~@/assets/ColdCall/31475-1.png" />
                  </div>
                  <div class="itemInfo">
                    <span>1</span>
                    <span>12</span>
                    <span class="icon-1"></span>
                    <span class="icon-2"></span>
                  </div>
                  <div class="itemInfoName">王嘉声</div>
                </div>
              </el-tooltip>
            </li> -->
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  coldcallTopicResultSubmit,
  getTopicResultColdCallMemberListByTopic,
  getColdCallMemberResult,
} from "@/api/memberTopicResultController";
import { secondsToTimeStr } from "@/utils/validate";
import Topic from "@/components/Question/Topic.vue";
export default {
  name: "ColdCall",
  data() {
    return {
      ColdCallShow: true,
      type: 0,
      answer: "",
      countdownTimeout: -1,
      countdown: 0,
      coldCallAnswers: [{}],
      isAnswerSubmit: false,
      startTime: 0,
      memberList: [],
      memberAnswer: "",
      flag: false,
      memeberIndex: -1,
    };
  },
  created() {
    //this.queryForm.courseId = id;
    this.type == 0;
    this.answer = "";
    this.coldCallCountdown(this.coldCallData.duration);
    this.startTime = new Date().getTime();
  },
  props: {
    coldCallData: {
      type: Object,
      default: null,
    },
  },
  watch: {
    coldCallData: {
      // eslint-disable-next-line no-unused-vars
      handler(val, oldval) {
        this.type == 0;
        this.answer = "";
        this.coldCallCountdown(this.coldCallData.duration);
      },
      deep: true,
    },
  },
  beforeDestroy() {
    window.clearTimeout(this.countdownTimeout);
  },
  methods: {
    setTopicAnswers(practiceIndex, topicIndex, topicAnswers) {
      this.coldCallAnswers[topicIndex] = topicAnswers;
    },
    coldCallCountdown(duration) {
      window.clearTimeout(this.countdownTimeout);
      let that = this;
      this.countdown = secondsToTimeStr(duration);
      if (duration == 0) {
        this.submitAnswer(1);
        return;
      }
      this.countdownTimeout = setTimeout(function () {
        that.coldCallCountdown(--duration);
      }, 1000);
    },
    close() {
      this.type = 0;
      this.$emit("cold-call-success");
    },
    submitAnswer(type) {
      if (type == 0) {
        let answersLength = this.coldCallAnswers[0].answers.length;
        for (let i = 0; i < answersLength; i++) {
          if (this.coldCallAnswers[0].answers[i].ret == "BLANK") {
            this.$baseMessage(
              "答案不能为空!",
              "warning",
              "vab-hey-message-warning"
            );
            return;
          }
        }
      }
      window.clearTimeout(this.countdownTimeout);
      // const id = sessionStorage.getItem("COURSE_ID");
      // let topicAnswers = {
      //   answers: [],
      //   refId: this.coldCallData.id,
      //   refKind: "ColdCall",
      //   scores: 0,
      //   spend: 0,
      //   topicId: this.coldCallData.topic.id,
      //   courseId: id,
      // };
      // let questionAnswer = {
      //   approved: true,
      //   fieldsAnswers: [],
      //   questionId: this.coldCallData.topic.questions[0].id,
      //   ret: "BLANK",
      //   scores: 0,
      //   weight: 0,
      // };
      // questionAnswer.fieldsAnswers.push({
      //   itemId: "-1",
      //   itemLabel: "",
      //   itemValue: this.answer,
      // });
      // if (this.answer != "") {
      //   topicAnswers.scores = this.coldCallData.topic.scores;
      //   questionAnswer.scores = this.coldCallData.topic.scores;
      //   this.ret = "CORRECT";
      // }
      // topicAnswers.answers.push(questionAnswer);
      const id = this.getCourseId();//sessionStorage.getItem("COURSE_ID");
      let answerReport = {
        refId: this.coldCallData.id,
        refKind: "ColdCall",
        courseId: id,
        scores: this.coldCallAnswers[0].scores,
        spend: parseInt((new Date().getTime() - this.startTime) / 1000),
        topicAnswers: this.coldCallAnswers,
      };
      coldcallTopicResultSubmit(answerReport).then((res) => {
        if (res.code == 20000) {
          this.$baseMessage("提交成功", "success", "vab-hey-message-success");
          if (this.coldCallData.topic.type == "ESSAY_QUESTION") {
            this.type = 1;
            this.getTopicResultColdCallMemberListByTopic();
          }
          this.isAnswerSubmit = true;
        } else {
          this.$baseMessage(res.msg, "error", "vab-hey-message-error");
        }
      });
    },
    getCourseId() {
      let id = null; //sessionStorage.getItem("COURSE_ID");
      const key = "COURSE_ID";
      if (this.$cookies.isKey(key)) {
        id = this.$cookies.get(key);
      }
      return id;
    },
    getTopicResultColdCallMemberListByTopic() {
      let query = {};
      query.topicId = this.coldCallData.topic.id;
      query.page = 1;
      query.pageSzie = 10;
      getTopicResultColdCallMemberListByTopic(query).then((res) => {
        if (res.code == 20000) {
          this.memberList = res.data;
        } else {
          this.$baseMessage(res.msg, "error", "vab-hey-message-error");
          this.memeberIndex = -1;
        }
      });
    },
    getColdCallMemberResult(memberId) {
      this.memberAnswer = "正在加载答案...";
      getColdCallMemberResult(memberId, this.coldCallData.topic.id).then(
        (res) => {
          if (res.code == 20000) {
            this.memberAnswer = res.data[0].topicVo.questions[0].content;
          } else {
            //this.$baseMessage(res.msg, "error", "vab-hey-message-error");
            this.memberAnswer = res.msg;
          }
        }
      );
    },
    showAnswer(index) {
      console.log(this.flag);
      if (this.memeberIndex != index) {
        this.memeberIndex = index;
        this.getColdCallMemberResult(this.memberList[index].id);
        this.flag = true;
      }
    },
    showBu(row) {
      this.info = row;
      this.show = false;
    },
    showFn() {
      this.show = !this.show;
    },
  },
  components: {
    Topic,
  },
};
</script>

<style lang="scss" scoped>
.containerMain {
  background: #fafafa;
  position: relative;
  .containerMainRight {
    padding-left: 410px;
    .WrongQuestionCenter {
      width: 700px;
      margin: 0px auto;
      padding: 75px 0 15px 0;
      .WrongQuestionInfo {
        background: #f1e5b3;
        border-radius: 6px 6px 6px 6px;
        border: 1px solid #deb818;
        padding: 15px;
        .WrongQuestionInfo-title {
          font-size: 16px;
          font-weight: bold;
          color: #060606;
        }
        .WrongQuestionInfo-font {
          font-size: 16px;
          font-weight: 400;
          color: #060606;
          line-height: 24px;
          margin-top: 10px;
          span {
            font-size: 16px;
            font-weight: bold;
            color: #173d7c;
            text-decoration: underline;
            margin-left: 5px;
          }
        }
      }
      .WrongQuestion-Title {
        height: 50px;
        margin-top: 15px;
        .WrongQuestion-font {
          font-size: 36px;
          font-weight: 400;
          color: #060606;
          line-height: 50px;
          float: left;
        }
        .WrongQuestion-select {
          margin-top: 10px;
          height: 30px;
          background: #636363;
          border-radius: 15px 15px 15px 15px;
          opacity: 1;
          border: 1px solid #707070;
          font-size: 12px;
          font-weight: 400;
          color: #ffffff;
          position: relative;
          float: right;
          line-height: 30px;
          .selectValue {
            padding: 0 15px;
            span,
            i {
              float: left;
            }
            i {
              margin-left: 5px;
              margin-top: 3px;
            }
          }
          dl {
            display: none;
            background: #636363 79%;
            border-radius: 15px 15px 15px 15px;
            border: 1px solid #707070;
            position: absolute;
            left: 0;
            top: 30px;
            width: 100%;
            padding: 5px 0;
            dd {
              font-size: 12px;
              font-weight: 400;
              color: #ffffff;
              padding: 0 15px;
            }
          }
          &:hover dl {
            display: block;
          }
        }
      }
    }
  }
}
.EduHome {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  footer {
    padding: 24px 40px 40px;
    font-size: 16px;
    color: $color-text-6;
    background-color: $color-background-5;
  }
}
.WrongQuestion-or-title {
  line-height: 30px;
  padding: 15px 0 5px 0;
  .orTitle-left {
    padding-right: 150px;
  }
  .orTitle-right {
    float: right;
    span,
    i,
    div {
      float: left;
    }
    i {
      margin-top: 5px;
      height: 25px;
      font-size: 0;
    }
    div {
      margin-left: 10px;
      height: 30px;
      background: #636363;
      display: block;
      border-radius: 16px;
      padding: 0 10px;
      color: #ffffff;
      span {
        margin-left: 5px;
        line-height: 30px;
      }
    }
  }
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}
.WrongQuestion-or-text {
  padding-top: 10px;
  textarea {
    padding: 5px 10px;
    border: 1px solid #707070;
    height: 350px;
    line-height: 24px;
    font-size: 16px;
    width: 100%;
  }
}
.WrongQuestion-explain {
  background: #ffffff;
  border: 1px solid #aeaeae;
  opacity: 1;
  border-radius: 6px;
  padding: 15px 20px;
  margin-top: 20px;
  .explainTitle {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #636363;
    opacity: 1;
    padding-left: 30px;
    background: url("~@/assets/outline/iconDown.png") no-repeat left;
    background-size: 16px 17px;
    line-height: 36px;
  }
  .explainCenter {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #636363;
    opacity: 1;
    line-height: 24px;
    margin-top: 20px;
  }
  &.on {
    background: #ede2e3;
    border: 1px solid #8d0005;
    opacity: 1;
    border-radius: 6px;
    .explainTitle {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #060606;
      opacity: 1;
      background: url("~@/assets/outline/naviconitem-3.png") no-repeat left;
    }
    .wrongReason {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #060606;
      opacity: 1;
      margin-top: 20px;
      padding-left: 30px;
    }
    .explainCenter {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 24px;
      color: #060606;
      opacity: 1;
      padding-left: 30px;
      a:link,
      a:visited {
        color: #8d0005;
      }
    }
  }
}
.WrongQuestion-Page {
  padding: 20px 0;
  text-align: center;
  ul {
    display: inline-block;
    li {
      float: left;
      margin: 0 5px;
      border: 1px solid #eeeeee;
      width: 32px;
      height: 32px;
      background: #ffffff;
      border-radius: 3px 3px 3px 3px;
      border: 1px solid #e9e9e9;
      font-size: 12px;
      font-weight: 300;
      color: #999999;
      text-align: center;
      line-height: 32px;
      cursor: pointer;
      &.on {
        width: 32px;
        height: 32px;
        background: #173d7c;
        border-radius: 3px 3px 3px 3px;
        opacity: 1;
        color: #ffffff;
      }
    }
  }
  &::after {
    display: block;
    clear: both;
    content: "";
  }
}
.WrongQuestion-or {
  margin-top: 10px;
  border: 1px solid #d6d6d6;
  opacity: 1;
  border-radius: 6px;
  dl {
    dd {
      .WrongQuestion-orDdTitle {
        padding: 20px 14px;
        label {
          cursor: pointer;
          display: block;
          position: relative;
          input {
            float: left;
            margin-top: 6px;
          }
          div {
            padding-left: 35px;
            padding-right: 35px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 24px;
            color: #060606;
            opacity: 1;
          }
        }
      }
      .WrongQuestion-info {
        background: #e8e8e8;
        opacity: 1;
        border-radius: 0px;
        padding: 20px 20px 20px 50px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 24px;
        color: #060606;
        opacity: 1;
      }
    }
  }
}
.ColdCallShow {
  background-color: rgba($color: #000000, $alpha: 0.6);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  padding: 120px 80px;
  overflow: auto;
  z-index: 1;
  .ColdCallInfo {
    width: 700px;
    background: #ffffff;
    border: 1px solid #575757;
    padding: 50px 30px;
    position: relative;
    box-sizing: border-box;
    border-radius: 8px;
    margin: 0 auto;
    .icon-gb {
      position: absolute;
      right: 10px;
      top: 10px;
      width: 24px;
      cursor: pointer;
      img {
        width: 24px;
      }
    }
    .ColdCallInfo-title {
      width: 78px;
      height: 46px;
      background: #000000;
      border-radius: 8px;
      text-align: center;
      top: -23px;
      left: 50%;
      margin-left: -39px;
      line-height: 46px;
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      color: #f5f5f5;
      position: absolute;
    }
    .qTitle {
      .q-icon {
        float: left;
        width: 46px;
        img {
          width: 46px;
        }
      }
      &::after {
        clear: both;
        display: block;
        content: "";
      }
      .qTitle-font {
        padding-left: 60px;
        font-size: 16px;
        font-weight: 400;
        color: #060606;
        line-height: 24px;
      }
    }
    .ColdCallInfo-mt30 {
      margin-top: 30px;
      border: 1px solid #575757;
      overflow: auto;
      box-sizing: border-box;
      padding: 5px 6px;
      height: 362px;
      position: relative;
      ul {
        li {
          float: left;
          width: 20%;
          padding: 5px 6px;
          box-sizing: border-box;
          .info {
            height: 165px;
            background: #ffffff;
            box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
            border: 1px solid #707070;
            box-sizing: border-box;
            padding: 15px 10px;
            text-align: center;
            &:hover {
              background: #173d7c;
              color: #ffffff;
              .itemInfo {
                color: #ffffff;
              }
              .itemInfoName {
                color: #ffffff;
              }
              .icon-1 {
                width: 12px;
                height: 12px;
                background: url("~@/assets/ColdCall/icon-3.png") no-repeat
                  center !important;
              }
              .icon-2 {
                width: 12px;
                height: 12px;
                background: url("~@/assets/ColdCall/icon-4.png") no-repeat
                  center !important;
              }
            }
            .imgInfo {
              width: 64px;
              margin: 0 auto;
              font-size: 0;
              img {
                width: 100%;
              }
            }
            .itemInfo {
              text-align: center;
              font-size: 12px;
              font-weight: 400;
              color: #605d5d;
              margin-top: 15px;
              span {
                display: inline-block;
                margin: 0 3px;
                line-height: 12px;
                img {
                  width: 12px;
                  cursor: pointer;
                }
                &.icon-1 {
                  width: 12px;
                  height: 12px;
                  background: url("~@/assets/ColdCall/icon-1.png") no-repeat
                    center;
                }
                &.icon-2 {
                  width: 12px;
                  height: 12px;
                  background: url("~@/assets/ColdCall/icon-2.png") no-repeat
                    center;
                }
              }
            }
            .itemInfoName {
              margin-top: 35px;
              height: 16px;
              font-size: 12px;
              font-weight: 400;
              color: #060606;
              line-height: 16px;
            }
          }
        }
      }
    }
  }
}
.wbf {
  font-size: 12px;
  color: #ffffff;
  width: 598px;
  box-sizing: border-box;
  text-align: left;
  .wbf-font {
    font-weight: 400;
    line-height: 24px;
  }
  .wbf-item {
    padding: 10px 0;
    line-height: 24px;
    &::after {
      clear: both;
      display: block;
      content: "";
    }
    .wbf-item-fl {
      float: left;
      .icon6 {
        background: url("~@/assets/ColdCall/icon-6.png") no-repeat left;
        padding-left: 15px;
        line-height: 24px;
        margin-left: 20px;
      }
    }
    .wbf-item-fr {
      float: right;
      background: url("~@/assets/ColdCall/icon-5.png") no-repeat left;
      padding-left: 15px;
      line-height: 24px;
    }
  }
}
.ColdCallInfoTitle1 {
  margin-top: 30px;
  font-size: 20px;
  font-weight: bold;
  color: #173d7c;
}
.ColdCallInfoFont {
  font-size: 16px;
  font-weight: 400;
  color: #060606;
  line-height: 24px;
  margin-top: 20px;
}
.ColdCallInfoFz16 {
  font-size: 16px;
  font-weight: bold;
  color: #060606;
  line-height: 24px;
  margin-top: 20px;
}
.ColdCallInfoTitle2 {
  font-size: 16px;
  font-weight: bold;
  color: #575b67;
  margin-top: 20px;
}
.ColdCallInfoTx {
  margin-top: 12px;
  textarea {
    width: 100%;
    height: 130px;
    border-radius: 0px 0px 0px 0px;
    border: 1px solid #575757;
    box-sizing: border-box;
    padding: 5px 10px;
    line-height: 21px;
    font-size: 14px;
    font-family: "Microsoft YaHei";
  }
}
.ColdCallInfoButton {
  margin: 0 auto;
  margin-top: 20px;
  width: 77px;
  height: 44px;
  background: #173d7c;
  border-radius: 8px;
  line-height: 44px;
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  cursor: pointer;
  text-align: center;
}
.ColdCallInfoTime {
  font-size: 16px;
  font-weight: 400;
  color: #060606;
  line-height: 24px;
  text-align: center;
  line-height: 36px;
  span {
    color: #8d0005;
  }
}
</style>
