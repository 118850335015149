import request from "@/utils/request";
const BASE_PATH = "/front/textbook/";

export function getPartDetailsById(id) {
  return request({
    url: BASE_PATH + "part/" + id + "/details",
    method: "get",
  });
}

export function getPartListByCourseId(courseId) {
  return request({
    url: BASE_PATH + "part/course/" + courseId,
    method: "get",
    //params: query
  });
}